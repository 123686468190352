HealthIcon = function ( textureOn, textureOff, x, y ){
	
	this.textureOn = textureOn;
	this.textureOff = textureOff;
	PIXI.Container.call( this );

	this.position.set( x, y );

	this.addSprites();

};

HealthIcon.prototype = Object.create( PIXI.Container.prototype );

HealthIcon.prototype.constructor = HealthIcon;

HealthIcon.prototype.addSprites = function() {

	this.lifeOn = new PIXI.Sprite( this.textureOn );
	this.lifeOn.anchor.set( 0.5, 0.5 );
	this.lifeOff = new PIXI.Sprite( this.textureOff );
	this.lifeOff.anchor.set( 0.5, 0.5 );
	
	this.addChild( this.lifeOff );
	this.addChild( this.lifeOn );
	this.lifeOn.visible = false;

};

HealthIcon.prototype.takeLife = function() {

	this.lifeOn.visible = true;
	TweenMax.fromTo( this.lifeOn.scale,1,{x:0.8,y:0.8,ease: Bounce.easeOut},{x:1,y:1,ease: Bounce.easeOut })
	TweenMax.fromTo( this.lifeOn,1,{alpha:0,ease: Bounce.easeOut},{alpha:1,ease: Bounce.easeOut })

};

HealthIcon.prototype.addLife = function() {
	
	this.lifeOn.visible = false;

};

