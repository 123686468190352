/*///////////////////////////////////////      	
           _           _                                    
 ___ _ __ | | __ _ ___| |__    ___  ___ _ __ ___  ___ _ __  
/ __| '_ \| |/ _` / __| '_ \  / __|/ __| '__/ _ \/ _ \ '_ \ 
\__ \ |_) | | (_| \__ \ | | | \__ \ (__| | |  __/  __/ | | |
|___/ .__/|_|\__,_|___/_| |_| |___/\___|_|  \___|\___|_| |_|
    |_|                                                     

*//////////////////////////////////////////////

/*
this.loader.add( 'uiTT', 'assets/sprites/ui/placeholder_TT.png' );
this.loader.add( 'splshBKG', 'assets/sprites/splashScreen/bg_splashscreen.jpg' );
this.loader.add( 'splshCredit', 'assets/sprites/splashScreen/credits-splashScreen.png' );
this.loader.add( 'splshHphone', 'assets/sprites/splashScreen/hPhones-splashScreen.png' );
*/

SplashScreen = function (  ){
	console.log('SPLASH SCREEN');
	PIXI.Container.call( this );
	this.screen = app.pixi.screen;

	this.addBk();
	this.addTagLine();
	this.addBox();
	this.addLogoCredits();
	this.addCopy();
//	this.initEvents();
	
};

SplashScreen.prototype = Object.create( PIXI.Container.prototype );

SplashScreen.prototype.constructor = SplashScreen;
	
	
	SplashScreen.prototype.addBk = function() {

		this.bkContainer = new PIXI.Container();
		
		var splshBkg1 = new PIXI.Sprite( this.getTexture( "splshBKG1" ) );
		splshBkg1.anchor.set( 0.5, 0.5 );
		//splshBkg1.position.set( -splshBkg1.width/2 , -splshBkg1.height/2 );
		this.bkContainer.addChild( splshBkg1 );

		var splshBkg2 = new PIXI.Sprite( this.getTexture( "splshBKG2" ) );
		splshBkg2.anchor.set( 0.5, 0.5 );
		
		this.bkContainer.addChild( splshBkg2 );

		var ratio = splshBkg1.height / splshBkg1.width;
		splshBkg2.width  = splshBkg1.width  = this.screen.width * 1.2;
		splshBkg2.height = splshBkg1.height = (this.screen.width * 1.2) * ratio;
		
		if(splshBkg1.height<this.screen.height ){

			splshBkg2.height = this.screen.height * 1.2;
			splshBkg2.width = splshBkg2.height/ratio;
			
			splshBkg1.height = this.screen.height * 1.2;
			splshBkg1.width = splshBkg1.height/ratio;
		
		}

		//splshBkg2.position.set( -splshBkg1.width/2 , -splshBkg1.height/2 );
		//splshBkg1.position.set( -splshBkg1.width/2 , -splshBkg1.height/2 );

		this.bkContainer.position.set(this.screen.width/2,this.screen.height/2);
		splshBkg2.visible =false;
		this.bkContainer.posX = this.bkContainer.position.x;
 		this.bkContainer.posY = this.bkContainer.position.y;


		this.addChild( this.bkContainer );

		gyro.frequency = 10;
		gyro.startTracking( function( o ) {

			TweenMax.to( this.bkContainer.position, 2, { x : this.bkContainer.posX +  (20 * o.x) / 4, y : this.bkContainer.posY  + (10 * o.y) / 4  } );
	

		}.bind( this ) );
		this.switch = window.setInterval( this.flicker.bind( this ), 100 );

	
	};
	
	SplashScreen.prototype.flicker =function(){
	
		if(this.bkContainer.children[0].visible ==false){

			this.bkContainer.children[1].visible = false;
			this.bkContainer.children[0].visible = true;
		
		}else{

			this.bkContainer.children[0].visible = false;
			this.bkContainer.children[1].visible = true;

		}
	};


	SplashScreen.prototype.addTagLine = function() {



			var style0 = {
			
			"default": {
				fontFamily : localisation.fontApp,
				fontSize: localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#a48f65",
				wordWrap: true,
				wordWrapWidth: app.pixi.screen.width * 0.97037037,
				align: "center",
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance:3
    		},

			"red": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ff0000",
				align:"center",
				wordWrapWidth: app.pixi.screen.width * 0.92037037,
				wordWrap: true,
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance:3
			},
			
			"white" : {
				fontFamily: localisation.fontApp,
				fontSize: localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ffffff",
				align:"center",
				wordWrapWidth: app.pixi.screen.width * 0.92037037,
				wordWrap: true,
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance : 3
			}
			
		}
		this.splashTagLine =  new MultiStyleText(localisation.startScreen.tagLine.copy,style0)
		this.splashTagLine.anchor.set(0.5,0.5)
		this.splashTagLine.position.set( app.pixi.screen.width / 2 , app.pixi.screen.height*0.1 );
		this.splashTagLine.style.padding = 10;
		this.addChild(this.splashTagLine)

	};

	SplashScreen.prototype.addBox = function() {

		//OBX
		this.boxHeight = 700
		var uiBKwidth = this.screen.width * ( 690 / 1080 )
		var uiBKheight = uiBKwidth * this.boxHeight / 690; //app.pixi.screen.height * ( 944 / 1920 )
		this.uiBk = this.getRoundSquare(0xb6a07a,0x000000,0.4,uiBKwidth, uiBKheight,20 );

		this.addChild( this.uiBk );
		this.uiBk.position.set( this.screen.width/2, this.screen.height/2); 
	}
	
	SplashScreen.prototype.addLogoCredits = function() {

		this.logo = new PIXI.Sprite( this.getTexture( "uiTT" ) );
		this.logo.anchor.set( 0.5, 0.5 );
		
		this.percentScale( this.logo, .93 ); //SCALE DOWN TO MATCH 1080*1920
		
		this.logo.position.set( app.pixi.screen.width / 2 , ( app.pixi.screen.height - this.logo.height / 2 ) - 15 );
		this.addChild( this.logo );

		// CREDITS
		let creditStyle = new PIXI.TextStyle({
					fontFamily : localisation.fontApp,
					fontSize : localisation.startScreen.credits.fontsize * app.WIDTH_RATIO,
					fill: ['#a48f65'], // gradient
					wordWrap: true,
					wordWrapWidth: app.pixi.screen.width,
					align:"center",
					padding : 10
				});

		this.splshCreditText = new PIXI.Text( localisation.startScreen.credits.copy, creditStyle );
		
		this.splshCreditText.anchor.set(1,1);

		this.splshCreditText.position.set( this.screen.width - 20* app.WIDTH_RATIO, this.screen.height -20* app.WIDTH_RATIO );
		this.addChild( this.splshCreditText );

	};

	SplashScreen.prototype.addCopy = function() {

		let style = {
			
			"default": {
				fontFamily : localisation.fontApp,
				fontSize: localisation.startScreen.title.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#a48f65",
				wordWrap: true,
				wordWrapWidth: this.uiBk.width,
				align: "center"
			},

			"red": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.startScreen.title.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ff0000",
				align:"center",
				wordWrapWidth: this.uiBk.width,
				wordWrap: true
			},
			
			"white" : {
				fontFamily: localisation.fontApp,
				fontSize: localisation.startScreen.title.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ffffff",
				align:"center",
				wordWrapWidth: this.uiBk.width,
				wordWrap: true
			}
			
		}

		var copy1PosY = (- this.uiBk.height / 2 ) + this.uiBk.height * (64 / this.boxHeight);
		
		this.splashUItext2 =  new MultiStyleText(localisation.startScreen.title.copy2,style)
		this.splashUItext2.anchor.set(0.5,0)
		this.splashUItext2.position.set( 0 , copy1PosY );
		this.uiBk.addChild(this.splashUItext2);
		

		
		this.splashUItext3 =  new MultiStyleText(localisation.startScreen.title.copy3,style)
		var copy2PosY = this.splashUItext2.position.y+this.splashUItext2.height + 40 * app.WIDTH_RATIO
		this.splashUItext3.anchor.set(0.5,0)
		this.splashUItext3.position.set( 0 , copy2PosY );
		this.uiBk.addChild(this.splashUItext3);
		

		this.startBtn = this.getButton(localisation.startScreen.start,'#050506',true,'start');
		var startPosY =  this.uiBk.height/2  - this.startBtn.height/2 - ( this.uiBk.height * (64 / this.boxHeight))
		this.startBtn.position.set(0, startPosY  );
		this.uiBk.addChild( this.startBtn );
		
		this.splashUItext2.style.padding = 10;
		this.splashUItext3.style.padding = 10;

	}
	
	SplashScreen.prototype.addAssets = function() {


		// BKG
		this.splshBkg = new PIXI.Sprite(this.getTexture("splshBKG"));
			this.splshBkg.position.set(0,0);
			
		this.addChild( this.splshBkg );
		
		// 
		///////////////////////////////////////////////////
   		//LOGO
   		///////////////////////////////////////////////////
		
	

		
	
		
		// BOX UI.
		this.splshUI = new PIXI.Container();
		this.splshUI.position.set(app.pixi.screen.width * 0.18, app.pixi.screen.height * 0.25);
		this.splshUI.width = app.pixi.screen.width * 0.637962963;
		this.splshUI.height = app.pixi.screen.height * 0.395833333;
		
			
			// Outline
			this.splshUIoutline = new PIXI.Graphics();
				this.splshUIoutline.lineStyle(2, 0xB9A071, 1);
				this.splshUIoutline.beginFill(0x000000, 0);
				this.splshUIoutline.drawRoundedRect(
					0, 0, 
					app.pixi.screen.width * 0.637962963, app.pixi.screen.height * 0.465833333, 
					app.pixi.screen.width * 0.050925926 
				);
				this.splshUIoutline.position.set( 0, 0);
			
			// Blur 
			this.splshUIblur = new PIXI.Graphics();
				
				let blur = new PIXI.filters.BlurFilter();
					blur.blur = (app.pixi.screen.width * 0.085185185) / 2;
					
				this.splshUIblur.lineStyle(2, 0x000000, 0);
				this.splshUIblur.beginFill(0x000000, 0.74);
				this.splshUIblur.drawRoundedRect(
					0, 0, 
					app.pixi.screen.width * 0.937962963, app.pixi.screen.height * 0.465833333, 
					app.pixi.screen.width * 0.050925926 
				);
			//	this.splshUIblur.filters = [blur];
			//	this.splshUIblur.position.set( -0.2, 0);
				
			
				this.txtStyle = new PIXI.TextStyle({
					fontFamily : 'Roboto',
					fontSize :  app.pixi.screen.height * 0.029166667,
					fill: ['#b6a07a'], // gradient
					wordWrap: true,
					wordWrapWidth: app.pixi.screen.width * 0.52037037,
					align:"center"
				});
				
			// TEXT 1

			

				
			// TEXT 2	
				this.splashUItext2 =  new MultiStyleText(localisation.startScreen.title.copy2,style)
				this.splashUItext2.position.set( this.splshUIoutline.width/2, app.pixi.screen.height * 0.13125);
				this.splashUItext2.anchor.set(0.5,0)
			// Line
				
			// HPHONE  
				this.splshHphone = new PIXI.Sprite(this.getTexture("splshHphone"));
				this.splshHphone.position.set(app.pixi.screen.width * 0.103703704 , app.pixi.screen.height * 0.310375);
				this.percentScale( this.splshHphone, 0.047111111);
				
				this.txtStyle = new PIXI.TextStyle({
					fontFamily : localisation.fontApp,
					fontSize : localisation.startScreen.headphones.fontSize * app.WIDTH_RATIO ,
					fill: ['#a48f65'], // gradient
					wordWrap: false,
					align:"center",
					padding: 10
				});
				
			// TEXT 3
				this.splashUItext3 = new PIXI.Text( localisation.startScreen.headphones.copy, this.txtStyle);
				this.splashUItext3.position.set( app.pixi.screen.width * 0.183703704, app.pixi.screen.height * 0.310375);
				//this.splashUItext3.anchor.set(0,1)
				
			this.splshUI.addChild(this.splshUIblur);
			this.splshUI.addChild(this.splshUIoutline);
			this.splshUI.addChild(this.splashUItext1);

			this.splshUI.addChild(this.splashUItext2);
			this.splshUI.addChild(this.splshUIline);
			this.splshUI.addChild(this.splshHphone);
			this.splshUI.addChild(this.splashUItext3);
			this.splshUI.addChild(this.trailerBtn);
			
		this.addChild( this.splshUI );
		
	};
	
	SplashScreen.prototype.kill = function() {
		
		window.clearInterval( this.switch );

	};

	SplashScreen.prototype.initEvents = function() {
		
		//dummy code
/*
		var square = new PIXI.Graphics();
		square.interactive = true;
		square.lineStyle(1, 0xff000, 0);
		square.beginFill(0xff000, 0);
		square.drawRect(-100, -100, 200, 200 );
		square.position.set( app.pixi.screen.width / 2, app.pixi.screen.height / 2 );
*/
	
		//this.addChild( square );
	
	};
	SplashScreen.prototype.onBtnTouch = function( e ){
		
		var container = e.target;

		if( container.isReverse ){

			container.bk.visible = true;
			container.bkOn.visible = false;

			container.copy.style.fill = 0xb6a07a;
			
		}else{

			container.bk.visible = false;
			container.bkOn.visible = true;
			container.copy.style.fill = 0x050506;

		
		}
		//app.initSound();
		//app.dispatcher.dispatch( "START_GAME" )

		

		container.copy.dirty = true;
	};

	SplashScreen.prototype.onBtnTouchUp = function( e ){

		var container = e.target;

		if( container.isReverse ){

			container.bk.visible = false;
			container.bkOn.visible = true;
			container.copy.style.fill = 0x050506;

		
		}else{
		
			container.bk.visible = true;
			container.bkOn.visible = false;
			container.copy.style.fill = 0xb6a07a;
		}
		container.copy.dirty = true;

	};

	/*///////////////////////////////////////
	 _          _                     
	| |__   ___| |_ __   ___ _ __ ___ 
	| '_ \ / _ \ | '_ \ / _ \ '__/ __|
	| | | |  __/ | |_) |  __/ |  \__ \
	|_| |_|\___|_| .__/ \___|_|  |___/
	             |_|                  

	*///////////////////////////////////////
	SplashScreen.prototype.getButton = function( copy, copyColor, active, type ){

		var container = new PIXI.Container();
		container.interactive = true;
		container.on( 'pointerdown', this.onBtnTouch );
		container.on( 'pointerup', this.onBtnTouchUp );

		var bk = this.getRoundSquare( 0xb6a07a, 0xa48f65, 0, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		var bkOn = this.getRoundSquare( 0xb6a07a, 0xa48f65, 1, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		
		container.addChild( bk );
		container.addChild( bkOn );
		container.bk = bk
		container.bkOn = bkOn;
		container.type = type;
		
		container.isReverse = active;

		bkOn.visible = false;

		if( active ){
		
			bk.visible = false;
			bkOn.visible = true;
		
		}
		
		var text = new PIXI.Text( copy.copy , new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : copy.fontSize * app.WIDTH_RATIO  ,
   				fill : [ copyColor ], // gradient
   				wordWrap : true,
   				wordWrapWidth : 450,
   				textAlign : "center",
   				padding: 10

			})
		); 
		
		text.anchor.set(0.5,0.5);
		container.copy = text;
		container.addChild( text );
		return container;
	}

	SplashScreen.prototype.getRoundSquare = function( border,fill,fillAlpha, w, h,r ){

		var sq = new PIXI.Graphics();
		sq.lineStyle( 1, border, 1 );
		sq.beginFill( fill, fillAlpha );
		sq.drawRoundedRect( -w / 2, -h / 2, w, h, r );
		return sq;
	
	};

	SplashScreen.prototype.percentScale = function( item, percent ){

		if( item.width > app.pixi.screen.width * percent ){

			var ratio  = item.height / item.width;
			item.width = app.pixi.screen.width * percent;
			item.height = item.width * ratio;
		
		}

	};

	SplashScreen.prototype.getTexture = function( name ){

		return  app.loader.resources[ "" + name ].texture;
	
	};
