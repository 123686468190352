/*///////////////////////////////////////                  _                 _                                
 _ __  _ __ ___| | ___   __ _  __| |  ___  ___ _ __ ___  ___ _ __  
| '_ \| '__/ _ \ |/ _ \ / _` |/ _` | / __|/ __| '__/ _ \/ _ \ '_ \ 
| |_) | | |  __/ | (_) | (_| | (_| | \__ \ (__| | |  __/  __/ | | |
| .__/|_|  \___|_|\___/ \__,_|\__,_| |___/\___|_|  \___|\___|_| |_|
|_|                                        

	TAKES CARE OF PRELOADING ASSETS WITH INTRO AND OUTRO OF PRELOADER NOTIFIES APP ONCE ITS DONE                        
*////////////////////////////////////////

PreloadScreen = function ( preloader ) {
	
	this.loader = preloader;

	PIXI.Container.call( this );

	if(!localisation.snapchat) {
		this.start();

		this.preloadStarted = true;
	
	}
	this.intro();

};

PreloadScreen.prototype = Object.create( PIXI.Container.prototype );

PreloadScreen.prototype.constructor = PreloadScreen;


	PreloadScreen.prototype.start = function(){
		 
		 
		//UI
		this.loader.add( 'uiAudioTxt', 'assets/sprites/ui/ui_audiotxt.png' );
		//this.loader.add( 'uiTT', 'assets/sprites/ui/placeholder_TT.png' );

		this.loader.add( 'lifeOff1', 'assets/sprites/ui/ui_lives_I_nofill.png' );
		this.loader.add( 'lifeOn1', 'assets/sprites/ui/ui_lives_I_fill.png' );
		this.loader.add( 'lifeOff2', 'assets/sprites/ui/ui_lives_II_nofill.png' );
		this.loader.add( 'lifeOn2', 'assets/sprites/ui/ui_lives_II_fill.png' );
		this.loader.add( 'lifeOff3', 'assets/sprites/ui/ui_lives_III_nofill.png' );
		this.loader.add( 'lifeOn3', 'assets/sprites/ui/ui_lives_III_fill.png' );

		this.loader.add( 'timeBox', 'assets/sprites/ui/ui_timerbox.png' );
		this.loader.add( 'vignetteFrame', 'assets/sprites/ui/vignetteFrame.png' );
		this.loader.add( 'lights_strip', 'assets/sprites/ui_lights.png' );
		this.loader.add( 'light_red', 'assets/sprites/ui_lights_redglow.png' );
		this.loader.add( 'light_yellow', 'assets/sprites/ui_lights_yellowglow.png' );

		this.loader.add( 'vol1', 'assets/sprites/ui/vol1.png' );
		this.loader.add( 'vol2', 'assets/sprites/ui/vol2.png' );
		this.loader.add( 'volOff', 'assets/sprites/ui/volOff.png' );
		//decorations
		
		this.loader.add( 'destoy', 'assets/sprites/bg_tiledfloor_destroyed.png' );
		this.loader.add( 'destroyNormal', 'assets/sprites/bg_tiledfloor_destroyedNormal.png' );

		this.loader.add( 'leaf1', 'assets/sprites/leaf1.png' );
		this.loader.add( 'leafNormal1', 'assets/sprites/leaf1Normal.png' );
		this.loader.add( 'leaf2', 'assets/sprites/leaf2.png' );
		this.loader.add( 'leafNormal2', 'assets/sprites/leaf2Normal.png' );
		this.loader.add( 'leaf3', 'assets/sprites/leaf3.png' );
		this.loader.add( 'leafNormal3', 'assets/sprites/leaf3Normal.png' );
		this.loader.add( 'leaf4', 'assets/sprites/leaf4.png' );
		this.loader.add( 'leafNormal4', 'assets/sprites/leaf4Normal.png' );
	
		this.loader.add( 'banisterTop', 'assets/sprites/bg_banister_top_tile_2.png' );
		this.loader.add( 'banisterTopNormal', 'assets/sprites/bg_banister_top_tile_2Normal.png' );
		this.loader.add( 'banisterBottom', 'assets/sprites/bg_banister_bot_tile_2.png' );
		this.loader.add( 'banisterBottomNormal', 'assets/sprites/bg_banister_bot_tile_2Normal.png' );
		this.loader.add( 'banister', 'assets/sprites/bg_banister_tile_2.png' );
		this.loader.add( 'banisterNormal', 'assets/sprites/bg_banister_tile_2Normal.png' );
	
	
		this.loader.add( 'crack1', 'assets/sprites/bg_tiledfloor_crack_1.png' );
		this.loader.add( 'crackNormal1', 'assets/sprites/bg_tiledfloor_crackNormal_1.png' );
		this.loader.add( 'crack2', 'assets/sprites/bg_tiledfloor_crack_2.png' );
		this.loader.add( 'crackNormal2', 'assets/sprites/bg_tiledfloor_crackNormal_2.png' );
		this.loader.add( 'crack3', 'assets/sprites/bg_tiledfloor_crack_3.png' );
		this.loader.add( 'crackNormal3', 'assets/sprites/bg_tiledfloor_crackNormal_3.png' );
	
		this.loader.add( 'debri1', 'assets/sprites/bg_tiledfloor_debris_1.png' );
		this.loader.add( 'debriNormal1', 'assets/sprites/bg_tiledfloor_debrisNormal_1.png' );
	
		this.loader.add( 'leftFeet', 'assets/sprites/footprint_L1.png' );
		this.loader.add( 'rightFeet', 'assets/sprites/foorprints_R1.png' );
	
		this.loader.add( 'floor1', 'assets/sprites/bg_woodfloor_tile.jpg');
		this.loader.add( 'floorNormal1', 'assets/sprites/bg_woodfloor_tile_n.png');
		this.loader.add( 'floor2', 'assets/sprites/bg_stairfloor_tile.jpg' );
		this.loader.add( 'floorNormal2', 'assets/sprites/bg_stairfloor_tile.png' );
		this.loader.add( 'floor3', 'assets/sprites/bg_tiledfloor_tile.jpg' );
		this.loader.add( 'floorNormal3', 'assets/sprites/bg_tiledfloor_tileNormal.png' );
		this.loader.add( 'floor2Trans2', 'assets/sprites/bg_transition_stairTOtiled.jpg' );
		this.loader.add( 'floorNormal2Trans2', 'assets/sprites/bg_transition_stairTOtiled.png' );
		this.loader.add( 'floor2Trans1', 'assets/sprites/bg_transition_woodTOstair.jpg' );
		this.loader.add( 'floorNormal2Trans1', 'assets/sprites/bg_transition_woodTOstairNormal.png' );

		this.loader.add( 'floor1Trans1', 'assets/sprites/bg_transition_tiledTOwood.jpg' );
	//	this.loader.add( 'floorNormal1Trans1', 'assets/sprites/bg_transition_woodTOstairNormal.png' );



		this.loader.add( 'tile1', 'assets/sprites/paint_1.png' );
		this.loader.add( 'tileNormal1', 'assets/sprites/paint_1Normal.png' );
		this.loader.add( 'tile2', 'assets/sprites/paint_2.png' );
		this.loader.add( 'tileNormal2', 'assets/sprites/paint_2Normal.png' );
		this.loader.add( 'tile3', 'assets/sprites/paint_3.png' );
		this.loader.add( 'tileNormal3', 'assets/sprites/paint_3Normal.png' );
		
		// Spalsh screen stuff

		this.loader.add( 'splshBKG1', 'assets/sprites/splashScreen/start_bg1.jpg' );
		this.loader.add( 'splshBKG2', 'assets/sprites/splashScreen/start_bg2.jpg' );
		this.loader.add( 'splshCredit', 'assets/sprites/splashScreen/credits-splashScreen.png' );
		

		this.loader.add( 'endBk', 'assets/sprites/endScreen/06_end_bg.jpg' );
		this.loader.add( 'endScareVideo', 'assets/videos/losemontage_half.mp4',	{ xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB } );
		this.loader.add( 'initVideo', 'assets/videos/startmontage_half.mp4',	{ xhrType: PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BLOB } );
	
		this.loader.add( "bk_share" ,"assets/sprites/share_assets/share.json");
		if( localisation.social.active ){
		
			this.loader.add( 'fb', 'assets/sprites/social_icons/social_fb.png' );
			this.loader.add( 'tw', 'assets/sprites/social_icons/social_tw.png');
			this.loader.add( 'ins', 'assets/sprites/social_icons/social_ig.png' );
			this.loader.add( 'whats', 'assets/sprites/social_icons/social_wt.png' );
		
		}
		


		this.loader.on( 'progress', this.onProgress.bind( this ) );
	
		this.loader.on( 'complete', this.onComplete.bind( this ) );
		this.loader.load();
	}
	
	PreloadScreen.prototype.intro = function(){

		
		var style0 = {
			
			"default": {
				fontFamily : localisation.fontApp,
				fontSize: localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#a48f65",
				wordWrap: true,
				wordWrapWidth: app.pixi.screen.width * 0.97037037,
				align: "center",
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance:3,
    			padding: 10,
			},

			"red": {
				fontFamily: localisation.fontApp,
				fontSize:  localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ff0000",
				align:"center",
				wordWrapWidth: app.pixi.screen.width * 0.92037037,
				wordWrap: true,
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance:3,
    			padding: 10,
			},
			
			"white" : {
				fontFamily: localisation.fontApp,
				fontSize:  localisation.startScreen.tagLine.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ffffff",
				align:"center",
				wordWrapWidth: app.pixi.screen.width * 0.92037037,
				wordWrap: true,
				dropShadow : true,
    			dropShadowColor : '#00000088',
    			dropShadowDistance : 3,
    			padding: 10,
			}
			
		}
		this.splashUItext1 =  new MultiStyleText(localisation.preloadScreen.tagLine.copy,style0)
		this.splashUItext1.anchor.set(0.5,0.5)
		
		this.splashUItext1.position.set( app.pixi.screen.width / 2 , app.pixi.screen.height*0.1 );
		
		this.addChild(this.splashUItext1)

		///////////////////////////////////////////////////
   		//TT
   		///////////////////////////////////////////////////
		
		this.uiTitle = new PIXI.Sprite( this.getTexture( "uiTT" ) );
		this.uiTitle.anchor.set( 0.5, 0 );
		this.percentScale( this.uiTitle, 1 ); //SCALE DOWN TO MATCH 1080*1920
		
		this.UITitleHolder = new PIXI.Container();

		this.uiTitle.position.set( app.pixi.screen.width / 2 , 0 );
		this.UITitleHolder.addChild( this.uiTitle );
		
		
		this.txtStyle = new PIXI.TextStyle({
			fontFamily : localisation.fontApp,
			fontSize : localisation.preloadScreen.headphones.fontSize * app.WIDTH_RATIO ,
			fill: ['#a48f65'], // gradient
			wordWrap: false,
			align:"center",
			padding: 10
		});
				
		// Headphones Text
		this.splashUItext3 = new PIXI.Text( localisation.preloadScreen.headphones.copy, this.txtStyle);
		this.splashUItext3.anchor.set(0.5,1)
		this.splashUItext3.position.set( app.pixi.screen.width/2, app.pixi.screen.height * 0.95);
		this.addChild( this.splashUItext3 );

		// HPHONE ICON
		this.splshHphone = new PIXI.Sprite(this.getTexture("splshHphone"));
		this.splshHphone.anchor.set(1,0.5)
		this.splshHphone.position.set(this.splashUItext3.position.x - this.splashUItext3.width/2 - 20*app.WIDTH_RATIO , app.pixi.screen.height *  0.94);
		this.percentScale( this.splshHphone, 0.037111111);
		this.addChild( this.splshHphone );


		
		this.addChild( this.UITitleHolder);
		this.UITitleHolder.position.y = ( app.pixi.screen.height/2 ) - this.UITitleHolder.height / 2	
		
		if( !localisation.snapchat ){

			this.addFootSteps();
			this.addPreloadCopy();
			this.nextBtn = this.getButton(localisation.preloadScreen.next,'#050506',true,'next');
			this.nextBtn.position.set( app.pixi.screen.width/2 ,this.UITitleHolder.position.y + this.UITitleHolder.height )
	
		} 
		else{

			this.startBtn = this.getButton(localisation.preloadScreen.start,'#050506',true,'start');
			this.startBtn.position.set( app.pixi.screen.width/2 ,this.UITitleHolder.position.y + this.UITitleHolder.height*1.3 );

			this.addChild( this.startBtn );
			// CREDITS
		}
	//	var creditStyle = new PIXI.TextStyle({
	//				fontFamily : localisation.fontApp,
	//				fontSize : localisation.preloadScreen.credits.fontsize * app.WIDTH_RATIO,
	//				fill: ['#a48f65'], // gradient
	//				wordWrap: true,
	//				wordWrapWidth: app.pixi.screen.width,
	//				align:"center"
	//			});
//
	//	this.preloadCreditText = new PIXI.Text( localisation.preloadScreen.credits.copy, creditStyle );
	//	
	//	this.preloadCreditText.anchor.set(1,1);
//
	//	this.preloadCreditText.position.set( app.pixi.screen.width - 20* app.WIDTH_RATIO, app.pixi.screen.height -20* app.WIDTH_RATIO );
	//	this.addChild( this.preloadCreditText );

		


		
	}
	
	PreloadScreen.prototype.addFootSteps = function(){

		// PRELOADING PAINT
		var paintTexture = this.getTexture("loaderPaint")
		this.paint = new PIXI.extras.TilingSprite(paintTexture, app.pixi.screen.width,paintTexture.height);
		this.paint.anchor.set(0.5,0)

		this.paint.position.set(app.pixi.screen.width/2, this.uiTitle.position.y+this.uiTitle.height);
		this.addChild(this.paint)
		this.UITitleHolder.addChild( this.paint );

		this.coverPaint = new PIXI.Sprite(this.getTexture("coverPaint"));
		this.coverPaint.anchor.set(0,0);
		this.coverPaint.width = app.pixi.screen.width;

		this.coverPaint.position.set( 0, this.paint.position.y );
		this.UITitleHolder.addChild( this.coverPaint );
	
	};

	PreloadScreen.prototype.addPreloadCopy = function(){

		// PRELOAD COPY
		var preloadStyle = new PIXI.TextStyle({
			fontFamily : localisation.fontApp,
			fontSize : localisation.preloadScreen.preload.fontSize * app.WIDTH_RATIO ,
			fill: ['#a48f65'], // gradient
			wordWrap: false,
			align:"center",
			padding: 10
		});

		this.preloadText = new PIXI.Text( localisation.preloadScreen.preload.copy, preloadStyle );
		
		this.preloadText.anchor.set(0.5,0);

		this.preloadText.position.set( app.pixi.screen.width/2, this.paint.position.y + this.paint.height*1.2 );
		
		this.UITitleHolder.addChild( this.preloadText );
		this.UITitleHolder.position.y = ( app.pixi.screen.height/2 ) - this.UITitleHolder.height / 2

	};

	PreloadScreen.prototype.getRoundSquare = function( border,fill,fillAlpha, w, h,r ){

		var sq = new PIXI.Graphics();
		sq.lineStyle( 1, border, 1 );
		sq.beginFill( fill, fillAlpha );
		sq.drawRoundedRect( -w / 2, -h / 2, w, h, r );
		return sq;
	
	};
	PreloadScreen.prototype.outro = function(){
		
		TweenMax.to( this.position, 1, { y : "-=10" });
		TweenMax.to( this, 1, { alpha : 0, onComplete : function(){
			app.dispatcher.dispatch( "PRELOAD_COMPLETE" );
	
		}});

	}

	PreloadScreen.prototype.percentScale = function( item, percent ){

		if( item.width > app.pixi.screen.width * percent ){

			var ratio  = item.height / item.width;
			item.width = app.pixi.screen.width * percent;
			item.height = item.width * ratio;
		
		}

	};
	
	PreloadScreen.prototype.onBtnTouch = function(e){
		if(e.target.type == "next"){

			app.initSound();
			this.nextBtn.interactive = false;
			this.outro();
		
		}else{

			this.startBtn.interactive = false;
			this.startBtn.visible = false;
			app.initSound();
			this.start();
			this.addFootSteps();
			this.addPreloadCopy();

		}
		 

	};

	/*///////////////////////////////////////////////
	 _                     _ _               
	| |__   __ _ _ __   __| | | ___ _ __ ___ 
	| '_ \ / _` | '_ \ / _` | |/ _ \ '__/ __|
	| | | | (_| | | | | (_| | |  __/ |  \__ \
	|_| |_|\__,_|_| |_|\__,_|_|\___|_|  |___/
											 
	*///////////////////////////////////////////////
	///////////////////////////////////////
	PreloadScreen.prototype.getButton = function( copy, copyColor, active, type ){

		var container = new PIXI.Container();
		container.interactive = true;
		container.on( 'pointerdown', this.onBtnTouch.bind( this ) );

		var bk = this.getRoundSquare( 0xa48f65, 0xa48f65, 0, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		var bkOn = this.getRoundSquare( 0xa48f65, 0xa48f65, 1, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		
		container.addChild( bk );
		container.addChild( bkOn );
		container.bk = bk
		container.bkOn = bkOn;
		container.type = type;
		
		container.isReverse = active;

		bkOn.visible = false;

		if( active ){
		
			bk.visible = false;
			bkOn.visible = true;
		
		}
		
		var text = new PIXI.Text( copy.copy , new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : copy.fontSize * app.WIDTH_RATIO  ,
   				fill : [ copyColor ], // gradient
   				wordWrap : true,
   				wordWrapWidth : 450,
   				textAlign : "center",
   				padding: 10

			})
		); 
		
		text.anchor.set(0.5,0.5);
		container.copy = text;
		container.addChild( text );
		return container;
	}

	PreloadScreen.prototype.onTick = function(  ){
		if(!this.paint) return
		if(  this.paint.tilePosition.x<-5000){
			this.paint.tilePosition.x = 0;
		}
		if(this.paint) this.paint.tilePosition.x -= 2;
	}

	PreloadScreen.prototype.getTexture = function( name ){

		return  app.loader.resources[ "" + name ].texture;
	
	};

	PreloadScreen.prototype.onProgress = function( e ){
		var p = (  (e.progress-100) / 100 )
		this.coverPaint.position.x = app.pixi.screen.width * ( p )
	
	}
	
	PreloadScreen.prototype.onComplete = function( e ){

		app.loader.off( 'progress', this.onProgress.bind( this ) );
	
		app.loader.off( 'complete', this.onComplete.bind( this ) );
		if(!localisation.snapchat){

			this.addChild( this.nextBtn );
		
		}else{

			this.outro();
		
		}
		this.preloadText.visible = false
		//this.outro();
		this.onComplete = null;

	}	