var app = {
	
	/*//////////////////////////////////////////
	       _       _           _     
	  __ _| | ___ | |__   __ _| |___ 
	 / _` | |/ _ \| '_ \ / _` | / __|
	| (_| | | (_) | |_) | (_| | \__ \
	 \__, |_|\___/|_.__/ \__,_|_|___/
	 |___/                           
	
	*///////////////////////////////////////////

	CANVAS_CONTAINER : 'gameDiv',

	state : "PRE_PRELOAD",
	WIDTH_RATIO : 0,


	/*////////////////////////////////////////////
	 _       _ _   _       _ _                  
	(_)_ __ (_) |_(_) __ _| (_)_______ _ __ ___ 
	| | '_ \| | __| |/ _` | | |_  / _ \ '__/ __|
	| | | | | | |_| | (_| | | |/ /  __/ |  \__ \
	|_|_| |_|_|\__|_|\__,_|_|_/___\___|_|  |___/
	                                            
	*/////////////////////////////////////////////
	
	check : function(){
		
		if(Detectizr.device.type === "desktop") {
		
			app.showDesktopMessage();
		
		}else{

			if (window.innerHeight > window.innerWidth) app.init();
		
			else{

				app.showOrientationMessage();

			} 
				
		}

	},
	
	changeRes : function( res ){

		app.pixi.renderer.resolution = res;
		app.pixi.renderer.rootRenderTarget.resolution = res;
		app.pixi.renderer.resize( window.innerWidth-1, window.innerHeight );
		app.pixi.renderer.resize( window.innerWidth, window.innerHeight );
	},

	showDesktopMessage : function(){

		document.getElementById("blockers").style.display = "block";
		document.getElementById("desktop").style.display = "flex";
	
	},
	
	showOrientationMessage : function(){

		document.getElementById("blockers").style.height = window.innerHeight + "px";
		
		document.getElementById("blockers").style.display = "block";
		document.getElementById("mobile").style.display = "flex";

		app.listenerCheck = true;
		window.addEventListener( "resize", app.check)
	
	},
	
	checkOrientation : function(){
			
		if (window.innerHeight > window.innerWidth) {
			document.getElementById( app.CANVAS_CONTAINER ).style.display = "block"
			document.getElementById("blockers").style.display = "none";

			if(!app.muted)Howler.mute( false );
			if(app.playScreen){
				if(app.playScreen.parent){

					app.pixi.ticker.start()
					app.playScreen.timerInterval = window.setInterval( app.playScreen.onTimeTick.bind( app.playScreen ), 1000 )
				}
			}
			
		}
		
		else{

			document.getElementById( app.CANVAS_CONTAINER ).style.display = "none"
			document.getElementById("blockers").style.display = "block";
			document.getElementById("mobile").style.display = "flex";
			Howler.mute(true);

			if(app.playScreen){

				if(app.playScreen.parent){
					
					app.pixi.ticker.stop()
					window.clearInterval( app.playScreen.timerInterval );
				}
			}
		} 
	},

	init : function(){
		app.setVideoSize();
		if(app.listenerCheck){
			document.getElementById("blockers").style.display = "none";
			app.listenerCheck = false;
			window.removeEventListener( "resize", app.check)

		}
   		app.initEvents();
		app.initPixi();
		app.initPre_preloader();
		//app.initStats();
		app.initModal();
		app.WIDTH_RATIO = ( app.pixi.screen.width / 1080 );

	},

	initEvents : function(){
		window.addEventListener( "resize", app.checkOrientation );	
		app.dispatcher = new Dispatcher();
		app.dispatcher.addEventlistener( "INSTRUCTIONS", app.showInstructions );
		app.dispatcher.addEventlistener( "PRELOAD_COMPLETE", app.initSplash );
		app.dispatcher.addEventlistener( "START_GAME", app.initGame );
		app.dispatcher.addEventlistener( "RESTART", app.initGame );
		app.dispatcher.addEventlistener( "END_GAME", app.playScaryScreen );

		window.onblur  = app.onBlur;
		window.onfocus  = app.onFocus;

	},

	initStats : function(){

		app.stats = new Stats();
		document.body.appendChild( app.stats.domElement );
		app.stats.domElement.style.position = "absolute";
			app.stats.domElement.style.zIndex =999;
		app.stats.domElement.style.top = "5px";
		
	},
	
	initPixi : function() {

		app.ratio1 = 1.5//window.devicePixelRatio;
		
		
		app.pixi = new PIXI.Application( window.innerWidth, window.innerHeight, {
			legacy:true,
			transparent : true,
			resolution : 2,
			autoResize : true,
			interactive : true  

		} );
	
		document.getElementById( app.CANVAS_CONTAINER ).appendChild( app.pixi.view );
		app.pixi.ticker.add( app.onTick );

	},

	initPre_preloader : function(){

		app.loader = new PIXI.loaders.Loader();
		app.loader.add( 'uiTT', 'assets/img/placeholder_TT.png' );
		app.loader.add( 'splshHphone', 'assets/sprites/splashScreen/hPhones-splashScreen.png' );
		app.loader.add( 'loaderPaint', 'assets/sprites/preloader/loadingPaint.png' );
		app.loader.add( 'coverPaint', 'assets/sprites/preloader/coverPaint.png' );
		app.loader.on( 'complete', app.initPreloader);
		app.loader.load();
		
	},

	initPreloader : function(){
		app.setTextureRes();
		app.loader.off( 'complete', app.initPreloader);
		//app.loader.pause();
		app.state = "PRELOADER";
		app.loader2 = new PIXI.loaders.Loader();
		app.preloadScreen = new PreloadScreen( app.loader );
		app.pixi.stage.addChild( app.preloadScreen );
	
	},

	initSplash : function(){
		app.setVideoBlob();
		app.setTextureRes();

		//app.createTexturePointers();
		app.state = "SPLASH";
		app.pixi.stage.removeChild( app.preloadScreen );
		app.preloadScreen = null
		app.splashScreen = new SplashScreen( );
		app.pixi.stage.addChild( app.splashScreen );
		document.getElementById('videoCoverBTN').addEventListener("click",app.initScaryScreen)

	},
	
	initGame : function(){
		app.vidStartTimerSafe= window.clearTimeout( app.vidStartTimerSafe )
		document.getElementById("introVideo").removeEventListener('ended',app.initGame,false);
		document.getElementById("introVideo").style.display="none";
		if(app.endScreen){
			
			app.pixi.stage.removeChild( app.endScreen );
			app.endScreen = null;
		}
		app.state = "GAME";
		app.playScreen = new PlayScreen( );
		app.pixi.stage.addChild( app.playScreen );
	
	},

	playScaryScreen : function(){

		app.state = "none";
		app.updateRes(false)
		////////////////////////////
		//recreate blob
		document.getElementById("endVideo").src = "";
		URL.revokeObjectURL( app.blobEnd )
		app.blobEnd = URL.createObjectURL(app.loader.resources.endScareVideo.data );
		document.getElementById("endVideo").src = app.blobEnd;
		////////////////////////
		app.vidEndTimerSafe = window.setTimeout( app.initEndScreen, 11000 )

		document.getElementById("endVideo").currentTime = 0;
		document.getElementById("endVideo").play();
		document.getElementById("endVideo").style.display="block";

		app.pixi.stage.removeChild( app.playScreen );

		
		document.getElementById("endVideo").addEventListener('ended',app.initEndScreen,false);
    	document.getElementById("endVideo").onwaiting = function(){
   			
   			app.danger1.pause();
   			document.getElementById("endVideo").currentTime = document.getElementById("endVideo").currentTime-0.1;
   		
			console.log("waitings")
		
		};
		document.getElementById("endVideo").onplaying = function(){
			console.log("playing")
   			app.danger1.play();
			
		};
		app.addTT();
		//app.pixi.ticker.stop()

	},

	showInstructions : function(){
	
		app.pixi.stage.removeChild( app.endScreen );
		app.state = "SPLASH";
		app.splashScreen = new SplashScreen( );
		app.pixi.stage.addChild( app.splashScreen );
		document.getElementById('videoCoverBTN').addEventListener("click",app.initScaryScreen)
		TweenMax.delayedCall(1,function(){
			document.getElementById('videoCoverBTN').style.display = "block";
		})

	},

	forcereplayEndVideo: function(){

		
	},

	addTT: function(){

		if( app.uiTitle ){

			if( app.uiTitle.parent ) app.pixi.stage.removeChild( app.uiTitle );
		//	if( app.preloadCreditText.parent ) app.pixi.stage.removeChild( app.preloadCreditText );

		}

		//	
		//		var creditStyle = new PIXI.TextStyle({
		//			fontFamily : 'dtl_albertinat_regular',
		//			fontSize : localisation.preloadScreen.credits.fontsize * app.WIDTH_RATIO,
		//			fill: ['#a48f65'], // gradient
		//			wordWrap: true,
		//			wordWrapWidth: app.pixi.screen.width,
		//			align:"center"
		//		});
//
		//app.preloadCreditText = new PIXI.Text( localisation.preloadScreen.credits.copy, creditStyle );
		
		//app.preloadCreditText.anchor.set(1,1);

		//app.preloadCreditText.position.set( app.pixi.screen.width - 20* app.WIDTH_RATIO, app.pixi.screen.height -20* app.WIDTH_RATIO );
		//app.pixi.stage.addChild( app.preloadCreditText );

		app.uiTitle = new PIXI.Sprite( app.splashScreen.getTexture( "uiTT" ) );
		app.uiTitle.anchor.set( 0.5, 0.5 );
		
		app.splashScreen.percentScale( app.uiTitle , .93 ); //SCALE DOWN TO MATCH 1080*1920
		
		app.uiTitle.position.set( app.pixi.screen.width / 2 , ( app.pixi.screen.height - app.uiTitle.height / 2 ) - 15 );
		app.pixi.stage.addChild( app.uiTitle );
	
	},

	initScaryScreen : function(){

		gtag('event', 'start_click', {'event_label': 'clicks strart','event_category': 'game starts'});
		document.body.removeEventListener("click",app.initScaryScreen)
		document.getElementById('videoCoverBTN').style.display = "none";

    	document.getElementById("introVideo").style.display="block";
    	document.getElementById("introVideo").style.zIndex=0;
    	document.getElementById("introVideo").addEventListener('ended',app.initGame,false);
    	document.getElementById("introVideo").currentTime = 0;
    	document.getElementById("introVideo").play();

    	app.vidStartTimerSafe = window.setTimeout( app.initGame, 8000 )
    	//if(Detectizr.os.name =="android"){
//
    	//	document.getElementById("endVideo").play();
    	//}
    //	document.getElementById("endVideo").play();
		if(app.splashScreen.parent){

			app.splashScreen.kill();
			app.pixi.stage.removeChild( app.splashScreen );
    		
    	}
    	app.addTT();
    	//if(!app.ambientSound) app.initSound();

	},
	
	initEndScreen : function(e,time){

		app.vidEndTimerSafe = window.clearTimeout( app.vidEndTimerSafe )
		app.danger1.pause();
		app.pixi.ticker.start()
		document.getElementById("endVideo").style.display = "none";
    	document.getElementById("endVideo").removeEventListener('ended',app.initEndScreen,false);
    	//document.getElementById("endVideo").currentTime = 0;
    	//document.getElementById("endVideo").play();
		app.ambientSound.play();
		app.state = "END_SCREEN";
		app.pixi.stage.removeChild( app.playScreen );
		app.endScreen = new EndScreen( time );
		app.pixi.stage.addChild( app.endScreen );

	},
	
	initSound : function(){ 

		document.getElementById('videoCoverBTN').style.display = "block";
		Howler.unload()
		app.step1  = new Howl( { src: [ 'assets/sounds/footstep01.mp3' ],volume:0.8 } );
		app.step2  = new Howl( { src: [ 'assets/sounds/footstep02.mp3' ],volume:0.8 } );
		app.step3  = new Howl( { src: [ 'assets/sounds/footstep03.mp3' ],volume:0.8 } );

		app.ambientSound = new Howl( { src: [ 'assets/sounds/atmospheric_loop.mp3' ], volume:1, loop:true } ) ;
		//app.ambientSound = new Audio();
		//app.ambientSound.volume = 0;
		////TweenMax.to(app.ambientSound,1,{volume:0.8})
		//app.ambientSound.src = 'assets/sounds/atmospheric_loop.mp3';
		//app.ambientSound.loop = true;
		app.ambientSound.play();

		app.levelUpSound = new Howl( { src: [ 'assets/sounds/newlevel.mp3' ],volume:0.7 } ) ;
		app.danger3 = new Howl( { src: [ "assets/sounds/danger1.mp3" ], volume : 0.5 } ) ;
		app.danger2 = new Howl( { src: [ "assets/sounds/danger2.mp3" ], volume : 0.6 } ) ;
		app.danger1 = new Howl( { src: [ "assets/sounds/danger3.mp3" ], volume : 0.6 } ) ;

		app.alienSound1 = new Howl( { src: [ "assets/sounds/alien01.mp3" ], volume : 0.2 } ) ;
		app.alienSound2 = new Howl( { src: [ "assets/sounds/alien02.mp3" ], volume : 0.2 } ) ;
		app.alienSound3 = new Howl( { src: [ "assets/sounds/alien03.mp3" ], volume : 0.2 } ) ;
		app.alienSound4 = new Howl( { src: [ "assets/sounds/alien04.mp3" ], volume : 0.2 } ) ;
	//	app.ambientSound.play();

	},

	
	updateRes : function( down ){
		if(down){
			var res = 1.5;
			if((Detectizr.device.type =="tablet")) res = 1
		}else{
			var res = 2;
		}
	
	
			app.pixi.renderer.resolution = res
			app.pixi.renderer.rootRenderTarget.resolution = res;
			var w = window.innerWidth;    
			var h = window.innerHeight;  
   			app.pixi.renderer.resize(w,h);
    		app.pixi.renderer.resize(w,h);
 			app.pixi.renderer.plugins.interaction.resolution = res;

	},

	initModal : function(){

		document.getElementById('js-modal-video').setAttribute( 'data-video-id', localisation.trailerLink )
		new ModalVideo('#js-modal-video', { 
			youtube : {
				autoplay : 1,
				enablejsapi :'1',
				rel : 0
			}

		 } );

	},
	onHideTrailer : function() {
		if(!app.muted){
			Howler.mute(false)
		}
	},
	setVideoBlob : function(){

		app.blobEnd = URL.createObjectURL(app.loader.resources.endScareVideo.data );
		document.getElementById("endVideo").src = app.blobEnd;
		//delete app.loader.resources.endScareVideo

		app.blobIntro = URL.createObjectURL(app.loader.resources.initVideo.data );
		document.getElementById("introVideo").src = app.blobIntro;
		//delete app.loader.resources.initVideo

	},
	
	setVideoSize : function(){
		
		var ratio = 540/960;
		var videoWidth = ratio * window.innerHeight;
		
		var ratio1 = 960/540;
		var videoHeight = ratio1 * window.innerWidth;

			if( videoWidth >= window.innerWidth ){

				document.getElementById( "introVideo" ).style.height = window.innerHeight + "px";
				document.getElementById( "endVideo" ).style.height = window.innerHeight + "px";
				document.getElementById( "introVideo" ).style.width = videoWidth + "px";
				document.getElementById( "endVideo" ).style.width = videoWidth + "px";

				document.getElementById( "introVideo" ).style.left =- ( ( videoWidth - window.innerWidth ) / 2 ) + "px";
				document.getElementById( "endVideo" ).style.left =  - ( ( videoWidth - window.innerWidth ) / 2 )	+ "px";	
 
			}else{

				document.getElementById( "introVideo" ).style.width = window.innerWidth + "px";
				document.getElementById( "endVideo" ).style.width = window.innerWidth + "px";
				document.getElementById( "introVideo" ).style.height = videoHeight + "px";
				document.getElementById( "endVideo" ).style.height = videoHeight + "px";

				document.getElementById( "introVideo" ).style.top = - ( ( videoHeight - window.innerHeight ) / 2 ) + "px";
				document.getElementById( "endVideo" ).style.top =   - ( ( videoHeight - window.innerHeight ) / 2 )	+ "px";	
 
			}
			

	},
	
	createTexturePointers : function(){
		
		for (var key in app.loader.resources.playAssets1.textures) {

			app.loader.resources[ key ] ={};
			app.loader.resources[ key ].texture = app.loader.resources.playAssets1.textures[ key ];
		}

		for (var key in app.loader.resources.playAssets2.textures) {
			
			app.loader.resources[ key ] ={};
			app.loader.resources[ key ].texture = app.loader.resources.playAssets2.textures[ key ];
		}

	},

setTextureRes : function(){

		for (var key in app.loader.resources) {
		
			if ( app.loader.resources.hasOwnProperty( key ) ) {
				
					if( (key !="bk_share") &&(key !="destoy")&& (key !="destroyNormal") &&(key !="floorNormal2Trans2")&&(key !="floor1Trans1")&&(key !="crack3")&&(key !="crackNormal3")&&(key !="debri1")&&(key !="debriNormal1")&&(key !="floor2Trans2")&&(key !="banisterNormal")&&(key !="crack1")&&(key !="crackNormal1")&&(key !="crack2")&&(key !="crackNormal2")&&(key !="banisterTop")&&(key !="banisterTopNormal")&&(key !="banisterBottom")&&(key !="banisterBottomNormal")&&(key !="banister")&&(key !="tile1") &&(key !="tileNormal1")&&(key !="tile2")&&(key !="tileNormal2")&&(key !="tile3")&&(key !="tileNormal3")&&( key!="leaf1")&&( key!="leafNormal1")&&( key!="leaf2")&&( key!="leafNormal2")&&( key!="leaf3")&&( key!="leafNormal3")&&( key!="leaf4")&&( key!="leafNormal4")&&( key!="vignetteFrame")
 && (key!="playAssets1") && (key!="playAssets2") && ( key!="floor2")&& ( key!="floor3") && ( key!="floorNormal3") && ( key!="floor1") && ( key!="floorNormal1") && ( key!="floorNormal2")&& ( key!="endScareVideo") && ( key!="initVideo")){

					app.loader.resources[ key ].texture.baseTexture.resolution = 2;
					app.loader.resources[ key ].texture.baseTexture.update();

				}
				
					
				
				
				
			}
		
		}

	},

	/*///////////////////////////////////////////////
	 _                     _ _               
	| |__   __ _ _ __   __| | | ___ _ __ ___ 
	| '_ \ / _` | '_ \ / _` | |/ _ \ '__/ __|
	| | | | (_| | | | | (_| | |  __/ |  \__ \
	|_| |_|\__,_|_| |_|\__,_|_|\___|_|  |___/
	                                         
	*///////////////////////////////////////////////
	
	onRestart : function(){
		app.pixi.stage.removeChild( app.endScreen );
		app.initScaryScreen();
	
	},

	onTick : function(){

		if( app.stats ) app.stats.update(); 

		if( app.state == "PRELOADER") app.preloadScreen.onTick();
		else if( app.state == "GAME") app.playScreen.onTick();

		else if( app.state == "END_SCREEN") app.endScreen.onTick();
	
	},

	onFocus : function(){
		if(!app.muted){
			Howler.mute(false);
		}
	},

	onBlur : function(){
		Howler.mute(true)
	}

	/*///////////////////////////////////////////////*/
};

function eventFire( el, etype ){

	if (el.fireEvent) {
	
		el.fireEvent( 'on' + etype);
	
	} else {
	
		var evObj = document.createEvent( 'Events' );

		evObj.initEvent( etype, true, false);
		
		el.dispatchEvent( evObj );
	
	}

}
window.onload = app.check;
