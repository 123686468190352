/*///////////////////////////////////////
       _                                     
 _ __ | | __ _ _   _ ___  ___ _ __ ___ _ __  
| '_ \| |/ _` | | | / __|/ __| '__/ _ \ '_ \ 
| |_) | | (_| | |_| \__ \ (__| | |  __/ | | |
| .__/|_|\__,_|\__, |___/\___|_|  \___|_| |_|
|_|            |___/                         

*////////////////////////////////////////

PlayScreen = function ( ) {
	
	PIXI.Container.call( this );

	/*///////////////////////////////////////
	       _       _           _     
	  __ _| | ___ | |__   __ _| |___ 
	 / _` | |/ _ \| '_ \ / _` | / __|
	| (_| | | (_) | |_) | (_| | \__ \
	 \__, |_|\___/|_.__/ \__,_|_|___/
	 |___/                           
	
	*////////////////////////////////////////

	this.initSpeed = 3;
	this.speed = 3;
	this.maxSpeed = 12;
	this.maxSpeedLevel1 = 12;
	this.maxSpeedLevel2 = 14;
	this.maxSpeedLevel3 = 16;
	this.increment = 0.006;
	this.steps = 0;
	this.gameStart = false;
	this.gamePenalty = false;
	this.lifes = 3;
	this.fakeTiles = [];
	this.level = 1;
	this.firstBanister = true;
	this.gameTime = 0;
	this.RATIO = window.devicePixelRatio;
	this.tileNum = 3;
	this.level2Num = 30;
	this.level3Num = 60;
	this.level4Num = 90;
	this.alienSoundCount = 0;
	this.alienSoundCountTotal = 1000;
	this.init();

};


	PlayScreen.prototype = Object.create( PIXI.Container.prototype );
	
	PlayScreen.prototype.constructor = PlayScreen;
	
	/*////////////////////////////////////////////
	 _       _ _   _       _ _                  
	(_)_ __ (_) |_(_) __ _| (_)_______ _ __ ___ 
	| | '_ \| | __| |/ _` | | |_  / _ \ '__/ __|
	| | | | | | |_| | (_| | | |/ /  __/ |  \__ \
	|_|_| |_|_|\__|_|\__,_|_|_/___\___|_|  |___/
	                                            
	*/////////////////////////////////////////////

	PlayScreen.prototype.init = function(){
	
		app.updateRes(true);
		this.now = 0;
		this.delta = 0;
		this.container = new PIXI.Container();
		this.uiGameContainer = new PIXI.Container();
		
		this.addChild( this.container );
		this.addChild( this.uiGameContainer );
		this.initGame();
		this.initGameUI();
		this.initCountDown();

	};
	/*////////////////////////////////////////////*/


	PlayScreen.prototype.initGame = function(){

		this.initLights();
		this.initFloor();
		this.initGyroLights();

	};

	PlayScreen.prototype.initFloor = function(){

		this.firstTile = true;
		var tx = this.getFloorTextures( 0 ); 
		this.floor = this.getLightSprite( tx.t, tx.n );
		
		var tx2 = this.getFloorTextures( - this.floor.height ); 
		
		this.floor2 = this.getLightSprite( tx2.t, tx2.n );
		this.floor2.position.y = - this.floor2.height;
		
		this.container.addChildAt( this.floor, 0 );
		this.container.addChildAt( this.floor2, 0 );

		this.floor.lights = this.allLights;
		this.floor2.lights = this.allLights;
		
		this.floor.position.x = this.floor2.position.x = - app.pixi.screen.width * .1

		this.floor2.interactive = this.floor.interactive = false;
		this.floor2.on( 'pointerdown', this.onTileMiss.bind( this ) );
		this.floor.on( 'pointerdown', this.onTileMiss.bind( this ) );

	};
	
	PlayScreen.prototype.initGyroLights = function(){

		gyro.frequency = 10;
		gyro.startTracking( function( o ) {

			TweenMax.to( this.clickLight.position, 0.2, { x : ( app.pixi.screen.width * app.ratio1 ) / 2 + 30 * o.x } );
			TweenMax.to( this.clickLight.position, 0.2, { y : ( app.pixi.screen.height * app.ratio1 ) / 2  + 10 * o.y } );
		
		}.bind( this ));
	
	};

	PlayScreen.prototype.initLights = function(){

		this.lightHeight = 80;  
		this.allLights = [];

		this.dirLight = this.getDirectionalLight();
		this.allLights.push( this.dirLight );  

		this.clickLight = this.getPointLight();
		this.allLights.push( this.clickLight );

		this.currentFeet = 0;

	};
	
	PlayScreen.prototype.initGameUI = function(){
		
		
		///////////////////////////////////////////////////
		// VIGNETTE
		///////////////////////////////////////////////////
		var vignette = new PIXI.Sprite( this.getTexture( "vignetteFrame" ) );
		vignette.width = app.pixi.screen.width;
		vignette.height = app.pixi.screen.height;
		this.uiGameContainer.addChild( vignette );

		//////////////////////////////////////////
		//LIGHTS
		//////////////////////////////////////////
		var lights = new PIXI.Sprite( this.getTexture( "lights_strip" ) );
		this.lightBulbs = [];

		this.lightBulbs.push( this.getLightGlow( 40, 80 ) );
		this.lightBulbs.push( this.getLightGlow( 160, 95 ) );
		this.lightBulbs.push( this.getLightGlow( 300, 95 ) );
		this.lightBulbs.push( this.getLightGlow( 425, 95 ) );
		this.lightBulbs.push( this.getLightGlow( 550, 80 ) );
		lights.addChild( this.lightBulbs[ 0 ] );
		lights.addChild( this.lightBulbs[ 1 ] );
		lights.addChild( this.lightBulbs[ 2 ] );
		lights.addChild( this.lightBulbs[ 3 ] );
		lights.addChild( this.lightBulbs[ 4 ] );
		this.percentScale( lights, 1 );
		lights.position.x = (app.pixi.screen.width / 2) - lights.width/2;
		lights.position.y = -50*app.WIDTH_RATIO
		this.addChild(lights)


		///////////////////////////////////////////////////
   		//TIME Display  
   		///////////////////////////////////////////////////

		this.uiTime = new PIXI.Sprite( this.getTexture( "timeBox" ) );
		this.uiTime.anchor.set( 0.5, 0.5 );

		this.percentScale( this.uiTime, .58 );  //SCALE DOWN TO MATCH 1080*1920

		this.uiTime.position.set( app.pixi.screen.width / 2 , app.pixi.screen.height * 0.1 );
		this.uiGameContainer.addChild( this.uiTime );
		
		var style = new PIXI.TextStyle({
   		
   				fontFamily : 'Roboto',
   				fontSize : 40,
   				fill: ['#b6a07a'], // gradient
   				wordWrap: true,
   				wordWrapWidth: 450,
   				textAlign:"center",
   				padding : 10
		
		});

		this.time = new PIXI.Text( "00:00:00",  style );
		this.time.anchor.set( 0.5, 0.5 );
		this.uiTime.addChild( this.time );
   		
   		///////////////////////////////////////////////////
   		//AUDIO DISPLAY
   		///////////////////////////////////////////////////
   		///////////////////////////////////////////////////
   		//AUDIO DISPLAY
   		///////////////////////////////////////////////////

		var audioStyle = new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : localisation.playScreen.audio.fontSize * app.WIDTH_RATIO  ,
   				fill : [ 0xa48f65 ], // gradient
   				wordWrap : true,
   				wordWrapWidth : 450,
   				textAlign : "center",
   				padding : 10

			})
		 
		this.uiAudioTxt = new PIXI.Text( localisation.playScreen.audio.copy, audioStyle);
		this.uiAudioTxt.anchor.set( 0.5, 0.5 );
   		this.uiAudioTxt.position.set( 0.84 * app.pixi.screen.width, this.uiTime.position.y );

   	//	this.percentScale( this.uiAudioTxt, ( this.uiAudioTxt.width * 2 / 1080 ) );  //SCALE DOWN TO MATCH 1080*1920
   		this.uiGameContainer.addChild( this.uiAudioTxt );


   		this.audioContainer = new PIXI.Container();

   		var soundIcon1 = new PIXI.Sprite( this.getTexture("vol1") )
		this.audioContainer.addChild(soundIcon1)
	
		soundIcon1.anchor.set(0.5);
		TweenMax.to(soundIcon1.scale, 0.3, { y :0.1,ease: Sine.easeInOut, repeat:-1, yoyo:true});

		var soundIcon2 = new PIXI.Sprite( this.getTexture("vol2") )
		this.audioContainer.addChild(soundIcon2)
		soundIcon2.anchor.set(0.5);
		var soundIcon3 = new PIXI.Sprite( this.getTexture("volOff") )
		this.audioContainer.addChild(soundIcon3)
		soundIcon3.anchor.set(0.5);
		soundIcon3.visible = false;

		if(app.muted){
			soundIcon1.visible = soundIcon2.visible = false;
			soundIcon3.visible = true;
		}
		TweenMax.to(soundIcon2.scale, 0.3, {delay:0.3,ease: Sine.easeInOut, y :0.1, repeat:-1, yoyo:true});

		this.uiGameContainer.addChild(this.audioContainer)
		this.audioContainer.position.set( this.uiAudioTxt.position.x + this.uiAudioTxt.width*1.2, this.uiTime.position.y );

		this.audioContainer.interactive = true;
		this.audioContainer.on( 'pointerdown', this.onSoundTouch.bind( this ) );
		this.audioContainer.hitArea = new PIXI.Rectangle(-75, -75, 150, 150); 
		//this.uiAudioTxt = new PIXI.Sprite( this.getTexture( "uiAudioTxt" ) );
		//this.uiAudioTxt.anchor.set( 0.5, 0.5 );
   		//this.uiAudioTxt.position.set( 0.86 * app.pixi.screen.width, this.uiTime.position.y );
//
   		//this.percentScale( this.uiAudioTxt, ( this.uiAudioTxt.width*2 / 1080 ) );  //SCALE DOWN TO MATCH 1080*1920
   		//this.uiGameContainer.addChild( this.uiAudioTxt );

   		///////////////////////////////////////////////////
   		//LIFES DISPLAY
   		///////////////////////////////////////////////////

   		this.healthBar = new PIXI.Container();
   		var healthIconGap = app.pixi.screen.width * 0.02;
   		this.healthIcons = [];

   		for(var i = 0; i < 3; i++){
   			
   			var life = new HealthIcon( this.getTexture( "lifeOn"+(i+1) ), this.getTexture( "lifeOff"+(i+1)  ),0,0 );
   			this.percentScale( life, 0.07 );
   			life.position.set( i * life.width / 1.2, 0 );
   			
   			this.healthBar.addChild( life );
   			this.healthIcons.push( life );

   		}
   		this.healthBar.position.set( 0.06 * app.pixi.screen.width, this.uiTime.position.y );
		this.uiGameContainer.addChild( this.healthBar )
   		
	
   		///////////////////////////////////////////////////
   		//TT
   		///////////////////////////////////////////////////
		
		this.uiTitle = new PIXI.Sprite( this.getTexture( "uiTT" ) );
		this.uiTitle.anchor.set( 0.5, 0.5 );
		
		this.percentScale( this.uiTitle, .93 ); //SCALE DOWN TO MATCH 1080*1920
		
		this.uiTitle.position.set( app.pixi.screen.width / 2 , ( app.pixi.screen.height - this.uiTitle.height / 2 ) - 15 );
		this.uiGameContainer.addChild( this.uiTitle );


		//	var creditStyle = new PIXI.TextStyle({
		//			fontFamily : localisation.fontApp,
		//			fontSize : localisation.preloadScreen.credits.fontsize * app.WIDTH_RATIO,
		//			fill: ['#a48f65'], // gradient
		//			wordWrap: true,
		//			wordWrapWidth: app.pixi.screen.width,
		//			align:"center"
		//		});
//
		//this.preloadCreditText = new PIXI.Text( localisation.preloadScreen.credits.copy, creditStyle );
		//
		//this.preloadCreditText.anchor.set(1,1);
//
		//this.preloadCreditText.position.set( app.pixi.screen.width - 20* app.WIDTH_RATIO, app.pixi.screen.height -20* app.WIDTH_RATIO );
		//this.uiGameContainer.addChild( this.preloadCreditText );
	
	};
	
	PlayScreen.prototype.initCountDown = function(  ){ 

		let countDownStyle = new PIXI.TextStyle({
			fontFamily : 'arial',
			fontSize : 280 * app.WIDTH_RATIO,
			fill: ['#ffffff'], // gradient

				stroke: "#000000",
				strokeThickness: 4,
			wordWrap: true,
			wordWrapWidth: app.pixi.screen.width,
			align:"center"
		});
		this.countDownText = new PIXI.Text( "3", countDownStyle );
		this.countDownText.anchor.set( .5 );
		this.countDownText.position.set( app.pixi.screen.width/2 , app.pixi.screen.height/2 );
		this.addChild( this.countDownText );
		
		TweenMax.fromTo(this.countDownText.scale, 0.3,{ x:0.7, y:0.7},{ x:1, y:1} );
		TweenMax.fromTo(this.countDownText, 0.4, {y:app.pixi.screen.height/2 ,alpha:1},{delay:0.5 ,y:"-=20",alpha:0})
		
		TweenMax.fromTo(this.countDownText.scale, 0.3,{ x:0.7, y:0.7},{delay:1, onStart:function(){
			this.countDownText.text = "2"
			this.countDownText.alpha = 1
			this.countDownText.position.set( app.pixi.screen.width/2 , app.pixi.screen.height/2 );
		}.bind(this),x:1, y:1} );
		TweenMax.fromTo(this.countDownText, 0.4 , {y:app.pixi.screen.height/2 ,alpha:1},{delay:1.5 ,y:"-=20",alpha:0})

		TweenMax.fromTo(this.countDownText.scale, 0.3,{ x:0.7, y:0.7},{delay:2, onStart:function(){
			this.countDownText.text = "1"
			this.countDownText.alpha = 1
			this.countDownText.position.set( app.pixi.screen.width/2 , app.pixi.screen.height/2 );
		}.bind(this),x:1, y:1} );
		TweenMax.fromTo(this.countDownText, 0.5, {y:app.pixi.screen.height/2 ,alpha:1},{delay:2.5 ,y:"-=20",alpha:0})


		TweenMax.fromTo(this.countDownText.scale, 0.3,{ x:0.7, y:0.7},{delay:3, onStart:function(){
			this.countDownText.text = localisation.playScreen.startMens.copy;
			this.countDownText.style.fontSize = localisation.playScreen.startMens.fontSize * app.WIDTH_RATIO
			this.countDownText.style.fill = ["#ff0000"]
			this.countDownText.alpha = 1
			this.countDownText.style.strokeThickness =0,
			this.countDownText.style.padding = 10;
			this.countDownText.position.set( app.pixi.screen.width/2 , app.pixi.screen.height/2 );
		}.bind(this),x:1, y:1} );
		TweenMax.fromTo(this.countDownText, 0.5, {y:app.pixi.screen.height/2 ,alpha:1},{delay:3.5 ,y:"-=20",alpha:0,onComplete:function(){

			this.gameStart = true;
			this.timerInterval = window.setInterval( this.onTimeTick.bind( this ), 1000 );
			this.floor2.interactive = this.floor.interactive = true;

			this.then = new Date().getTime();
		
		}.bind(this)})


	};
	
	PlayScreen.prototype.playAlienSound = function(){

		var num = this.getRandomArbitrary(1,4);
		console.log(num)
		app["alienSound"+ num].play();
		this.alienSoundCount = 0;
		this.alienSoundCountTotal = 400 + Math.round(Math.random() * 600 );
		
	};

	PlayScreen.prototype.clearButtons = function( num ){
		
		for( var i = num; i >= 0; i-- ){

			this.fakeTiles[ i ].parent.removeChild( this.fakeTiles[ i ] );
			this.fakeTiles.splice( i , 1 );
		
		}

	};
	
	PlayScreen.prototype.checkTiles = function(){

		for( var i = (this.tileNum-1) ; i >= 0; i-- ){

			if( this.lifes == 0 ) return;
			
			if( this.fakeTiles[ i ].children[ 1 ].alpha == 0 ) {

				var tilePos =  this.fakeTiles[ i ].parent.position.y - this.fakeTiles[ i ].position.y ;
	
				if( ( this.container.position.y + this.fakeTiles[ i ].position.y) >= app.pixi.screen.height ){
					if(!this.fakeTiles[ i ].out ){
						if(! this.fakeTiles[ i ].tabbed ){

							this.fakeTiles[ i ].out = true;
							
							this.onPlayerMiss();

						}
					}
					
				}
			
			}
		
		}
	};

	/*///////////////////////////////////////////////
	 _                     _ _               
	| |__   __ _ _ __   __| | | ___ _ __ ___ 
	| '_ \ / _` | '_ \ / _` | |/ _ \ '__/ __|
	| | | | (_| | | | | (_| | |  __/ |  \__ \
	|_| |_|\__,_|_| |_|\__,_|_|\___|_|  |___/
	                                         
	*///////////////////////////////////////////////
	
	PlayScreen.prototype.onSoundTouch = function( e ){
		var btn = e.target;
		//SOUND OFF
		if( btn.children[ 2 ].visible === false ){
			Howler.mute(true)
			app.muted = true;
			btn.children[ 2 ].visible = true;
			btn.children[ 0 ].visible = false;
			btn.children[ 1 ].visible = false;
		}else{
			//SOUND ON
			Howler.mute(false);
			app.muted = false;
			btn.children[ 2 ].visible = false;
			btn.children[ 0 ].visible = true;
			btn.children[ 1 ].visible = true;
		}

	}
	
	PlayScreen.prototype.onTimeTick = function(){

		this.gameTime += 1;

		var mins = Math.floor( this.gameTime / 60 );
		var secs =  this.gameTime - ( mins* 60 ) ;
	//	millisecs = this.gameTime - ( mins * 6000 ) - ( secs * 100)

	//	if( millisecs < 10 ) millisecs = "0" + millisecs;
		if( mins < 10 ) mins = "0" + mins;
		if( secs < 10 ) secs = "0" + secs;

		this.time.text =  "00:" +mins + ":" + secs  ;
	
	};

	PlayScreen.prototype.onTileMiss = function(e){
		if(this.gamePenalty)return;
		this.onPlayerMiss(e);

	};

	PlayScreen.prototype.onTileTouch = function(e){

		if(!this.gameStart)return;
		var tileRef = e.target;
		if( tileRef.tabbed ) return;
		
		tileRef.tabbed = true;

		TweenMax.to( this.container.position, 1, { x : ( ( app.pixi.screen.width / 2 ) - tileRef.position.x ) / 5  } );
		TweenMax.to( tileRef.children[ 1 + this.currentFeet ], 0.2, { alpha : 1 } );
		this.currentFeet ++;
	
		if(this.currentFeet > 1 ) this.currentFeet = 0;
		this.steps++
		app["step"+ this.getRandomArbitrary(1,3)].play()
		//app.step.play();

	};

	PlayScreen.prototype.onPlayerMiss = function(e){
		if(this.gamePenalty)return
		
		this.shake( this.container );
		TweenMax.to( this.dirLight, 0.3, { color : 0xff0000 } );
		
		if( this.penaltyInterval ) clearTimeout( this.penaltyInterval );
		
		this.penaltyInterval = setTimeout( function(){

			this.gamePenalty = false;
			
			TweenMax.to( this.dirLight, 0.3, { color : 0xffdd66,onComplete:function(){

				this.turnLights();

			}.bind( this ) });

		}.bind(this),2000);

		switch( this.level ){

			case 1 :this.initSpeed = 	(this.maxSpeedLevel1/5); break;
			case 2 :this.initSpeed = 	2 * (this.maxSpeedLevel2/5) ;break;
			case 3 :this.initSpeed = 	3 * (this.maxSpeedLevel3/5) ;break;

		}
		TweenMax.to( this,0.2, { speed : this.initSpeed } );
		this.onTakeLife();

		this.gamePenalty = true;
	//	this.speed = this.initSpeed;
		
		if(this.lifes == 0 ) this.onPlayerDead();
	
	};
	
	PlayScreen.prototype.onTakeLife = function(){
		if(this.lifes>1) app[ "danger" + this.lifes ].play();
		this.lifes --;
		this.healthIcons[ Math.abs( this.lifes - 2) ].takeLife();

	};

	PlayScreen.prototype.onPlayerDead = function(){
		this.interactive = false;
		app.gameTime = this.gameTime
		app.ambientSound.pause();
		app.dispatcher.dispatch( "END_GAME", this.gameTime );
		window.clearInterval( this.timerInterval );

	};

	PlayScreen.prototype.onTick = function(){	
		this.move();
		this.updateBoard();
		this.checkFloor();

		this.checkTiles();

		this.alienSoundCount++;
		if(this.alienSoundCount == this.alienSoundCountTotal) this.playAlienSound();

	};
	
	PlayScreen.prototype.updateBoard = function( ){
		

		if((this.steps>this.level2Num)&&(this.level==1)) {

			this.maxSpeed = this.maxSpeedLevel2;
			this.level = 2;
			app.levelUpSound.play()
		}
		if(this.steps>this.level3Num-1) this.lastBanister = true;

		if( ( this.steps > this.level3Num ) && ( this.level==2 ) ) {

			this.maxSpeed = this.maxSpeedLevel3;
			this.level3Exception = true;
			this.level = 3;
			app.levelUpSound.play()
		}
		if( ( this.steps > this.level4Num ) && ( this.level==3 ) ) {
			this.level = 1;
			this.loop = true;
			this.maxSpeedLevel1 = 18;
			this.maxSpeedLevel2 = 18;
			this.maxSpeedLevel3 = 18;
		}


	};
	
	PlayScreen.prototype.calcSpeed = function(del, speed) {

		return ( speed * 60 * del ) / 1000;
	
	};


	PlayScreen.prototype.move = function(){

		if( !this.gameStart ) return;
		
		this.container.position.y +=  this.speed;

		if ( (! this.gamePenalty ) && ( this.speed < this.maxSpeed ) ) this.speed += this.increment;

		var lightsOnNum = Math.floor( ( this.speed / this.maxSpeed )*this.lightBulbs.length);
		if(this.currentLightsOn!= lightsOnNum ){
			if(this.gamePenalty) return;
			this.currentLightsOn = lightsOnNum;

			this.turnLights();
		
		}
		

	};
	
	PlayScreen.prototype.turnLights = function(){
		for(var i = 0; i < this.lightBulbs.length; i++ ){

			this.lightBulbs[ i ].reset();

		}
		for(var i = 0; i < this.currentLightsOn; i++ ){

			this.lightBulbs[ i ].turnRed();

		}
	};

	PlayScreen.prototype.checkFloor = function(){

		if(this.floor2.worldTransform.ty > this.floor2.height ){
			if(this.level3Exception){
				sc = this.getScaleTextureScreen();
				this.floor2.height = app.pixi.screen.height*sc;

			}
			this.floor2.position.y -= this.floor.height + this.floor2.height;

			this.clearButtons( this.tileNum-1 );
			var tx = this.getFloorTextures( this.floor2.position.y );
		//	this.floor2.texture.destroy();
		//	this.floor2.normalTexture.destroy();
			this.floor2.texture = tx.t;
			this.floor2.normalTexture = tx.n;
		
		}
		
		if( this.floor.worldTransform.ty > this.floor.height ){
			if(this.level3Exception){
				sc = this.getScaleTextureScreen();
				this.floor.height = app.pixi.screen.height*sc
			}
			this.floor.position.y -= this.floor2.height + this.floor.height;
			this.clearButtons( this.tileNum-1 );
			var tx = this.getFloorTextures( this.floor.position.y );
		//	this.floor.texture.destroy();
		//	this.floor.normalTexture.destroy();
			this.floor.texture = tx.t;
			this.floor.normalTexture = tx.n;
		
		}

	};

	/*///////////////////////////////////////
	 _          _                     
	| |__   ___| |_ __   ___ _ __ ___ 
	| '_ \ / _ \ | '_ \ / _ \ '__/ __|
	| | | |  __/ | |_) |  __/ |  \__ \
	|_| |_|\___|_| .__/ \___|_|  |___/
	             |_|                  

	*///////////////////////////////////////
	
	PlayScreen.prototype.getLightGlow = function( x, y ){
		
		var light = new PIXI.Container();
		light.red =  new PIXI.Sprite( this.getTexture( "light_red" ) );
		light.red.anchor.set(0.5)
		light.yellow = new PIXI.Sprite( this.getTexture( "light_yellow" ) );
		light.yellow.anchor.set(0.5)
		light.addChild( light.red );
		light.addChild( light.yellow );
		light.position.set( x, y );
		light.red.visible = false;
		
		light.turnRed = function(light){
			light.yellow.visible = false;
			light.red.visible = true;

		}.bind(null,light)

		light.reset = function(light){
			light.yellow.visible = true;
			light.red.visible = false;

		}.bind(null,light)
		TweenMax.fromTo(light, 0.3, { alpha :0.8 },{delay:Math.random(), alpha : 0.9, repeat:-1, yoyo:true});
		return light;

	}

	PlayScreen.prototype.percentScale = function( item, percent ){

		if( item.width > app.pixi.screen.width * percent ){

			var ratio  = item.height / item.width;
			item.width = app.pixi.screen.width * percent;
			item.height = item.width * ratio;
		
		}

	};

	PlayScreen.prototype.getTexture = function( name ){

		return  app.loader.resources[ "" + name ].texture;
	
	};
	
	PlayScreen.prototype.getSquareTexture = function( w , h, color, alpha ){

		var square = new PIXI.Graphics();

		square.lineStyle(1, color, alpha);
		square.beginFill(color, alpha);
		square.drawRect(0, 0, w, h );

		var renderTexture = PIXI.RenderTexture.create( w, h );
		app.pixi.renderer.render( square, renderTexture );
		return renderTexture;
	
	};

	PlayScreen.prototype.shake = function ( item ) {

		TweenMax.fromTo( item.position, 0.1, { x :"+=-10", }, { x : "+=10", repeat : 5, yoyo : true, ease : Quad.easeInOut });
	
	};
	
	PlayScreen.prototype.getScaleTextureScreen = function(){

		return Math.ceil( app.pixi.screen.height / app.loader.resources[ "floor" + this.level].texture.height ) / ( app.pixi.screen.height / app.loader.resources[ "floor" + this.level].texture.height );
	
	};

	PlayScreen.prototype.getFloorTextures = function( yOffset ){

		if(this.loop){
			this.firstBanister = true;
			this.lastBanister = false;
			this.level = 1;
			this.steps = 0;

		}
		var texture = app.loader.resources[ "floor" + this.level   ].texture;
		var textureNormal = app.loader.resources["floorNormal" + this.level   ].texture;

		var numFloorTiles = Math.ceil( app.pixi.screen.height / texture.height );
		var sc = this.getScaleTextureScreen();
		var floor1 = new PIXI.extras.TilingSprite( texture, app.pixi.screen.width * 1.2, app.pixi.screen.height * sc );
		var floor2 = new PIXI.extras.TilingSprite( textureNormal, app.pixi.screen.width * 1.2, app.pixi.screen.height * sc );
		
		var isBroken = false;
		if( ( this.level == 2 ) && ( ( this.firstBanister ) || ( this.lastBanister ) ) ){
			
			var transTex = "Trans1"
			if(this.lastBanister) transTex = "Trans2"
			
			var texture = app.loader.resources[ "floor" + this.level + transTex  ].texture;
			var textureNormal = app.loader.resources["floorNormal" + this.level + transTex   ].texture;

			var floorTrans = new PIXI.extras.TilingSprite( texture, app.pixi.screen.width * 1.2,  texture.height );
			var floorTransN = new PIXI.extras.TilingSprite( textureNormal, app.pixi.screen.width * 1.2, texture.height);
			
			if( this.firstBanister ){

				floorTransN.position.y = floorTrans.position.y = floor1.height - floorTrans.height;
			}
			
			floor1.addChild(floorTrans);
		//ß	floor2.addChild(floorTransN);
		} 

		//loop 
		if(this.loop ) {
			this.loop = false;
			var transTex = "Trans1"
			
			var texture = app.loader.resources[ "floor" + this.level + transTex  ].texture;

			var floorTrans = new PIXI.extras.TilingSprite( texture, app.pixi.screen.width * 1.2,  texture.height );
			 floorTrans.position.y = floor1.height - floorTrans.height;
			
			floor1.addChild(floorTrans);
			this.loop = false;
			

		}

		if(this.level === 3){
			
			if(Math.random()>0.3){
				isBroken = true;
				var destroy = this.getDestroyTile( i );
				floor1.addChild( destroy.destroy );
				floor2.addChild( destroy.destroyNormal );
			
			}
		
		}

		for( var i = 0; i < this.tileNum; i++ ){
			

			if(this.level === 3){

				var crack = this.getCrack( i );
				floor1.addChild( crack.crack );
				floor2.addChild( crack.crackNormal );

				var debri = this.getDebri( i );
				floor1.addChild( debri.debri );
				floor2.addChild( debri.debriNormal );
			
			}

			var tile = this.getTile( i, numFloorTiles, sc,isBroken );
			floor1.addChild( tile.tile );
			floor2.addChild( tile.tileNormal );
			
			if(this.level == 1 ){

				var leaf = this.getLeaf( i );
				floor1.addChild( leaf.leaf );
				floor2.addChild( leaf.leafNormal );
			
			}

			

			this.getPrint( i, tile.tile, yOffset );

		}

		if(this.level == 2 ){

				var banister1  =  this.getHandlebars( sc );
				floor1.addChild( banister1.banister );
				floor2.addChild( banister1.banisterNormal );


		}

		var renderTexture = PIXI.RenderTexture.create( app.pixi.screen.width * 1.2, app.pixi.screen.height * sc );
		var renderTexture2 = PIXI.RenderTexture.create( app.pixi.screen.width * 1.2, app.pixi.screen.height * sc);

		app.pixi.renderer.render( floor1, renderTexture );
		app.pixi.renderer.render( floor2, renderTexture2 );
		
		floor2 = floor1 = null;

		return { t :renderTexture, n :renderTexture2 };

	};

	PlayScreen.prototype.getPrint = function( i, tile, yOffset  ){

		var fakeTile = new PIXI.Container();	
		
		var tileT = new PIXI.Sprite( tile.texture );
		tileT.scale.x = 1.3;
		tileT.position.x = - tileT.width*0.15
		tileT.alpha = 0;

		var print1;
		
		print0 = new PIXI.Sprite( app.loader.resources[ "leftFeet" ].texture );
		print1 = new PIXI.Sprite( app.loader.resources[ "rightFeet" ].texture );

		print1.alpha = print0.alpha = 0;

		print0.anchor.set( 0.5 );
		print0.position.set( tile.width / 2, tile.height / 2 );

		print1.anchor.set( 0.5 );
		print1.position.set( tile.width / 2, tile.height / 2 );

		fakeTile.addChild( tileT );
		fakeTile.addChild( print0 );
		fakeTile.addChild( print1 );
		fakeTile.position.set( tile.position.x- app.pixi.screen.width*.1, tile.position.y + yOffset );
		
		this.container.addChild( fakeTile );
		this.fakeTiles.push( fakeTile );

		fakeTile.interactive =true;

		fakeTile.on( 'pointerdown', this.onTileTouch.bind( this ) );
		if((this.firstTile)&&( i == 2 )){
		
			this.firstTile =false;
			fakeTile.tabbed = true;
			fakeTile.off( 'pointerdown', this.onTileTouch.bind( this ) );
		}

	};

	PlayScreen.prototype.getTile = function( i, numFloorTiles, sc,isBroken ){

		var row = Math.floor( i / 2 );
		var tileNum = this.getRandomArbitrary( 1, 4 );

		var tile = new PIXI.Sprite( app.loader.resources[ "tile" + tileNum ].texture );

		var tileN = new PIXI.Sprite( app.loader.resources[ "tileNormal" + tileNum ].texture )

		var tileY = ( (app.pixi.screen.height * sc ) / 3 * i );

		if(this.level == 2 ){

			var posTile = ( 135 ) * Math.floor( (i / 3 ) * numFloorTiles ) + 20;
			tileY = posTile;

		}

		var tileX = this.getRandomArbitrary( ( app.pixi.screen.width * 0.1 ), app.pixi.screen.width - tile.width - (app.pixi.screen.width * 0.1 ) );
		if(isBroken)  tileX = this.getRandomArbitrary( ( app.pixi.screen.width * 0.6 ), app.pixi.screen.width - tile.width - (app.pixi.screen.width * 0.1 ) );

		tile.position.set( tileX, tileY );
		tileN.position.set( tileX, tileY );
		//HIDE FIRST TILE FOR START GAME
		if( (this.firstTile )&&(i==2)){

			tileN.visible = tile.visible = false
		
		}
		return {tile : tile, tileNormal : tileN };

	};
	
	PlayScreen.prototype.getHandlebars = function(  sc ){

		var banisterTop = new PIXI.Sprite( app.loader.resources.banisterTop.texture );
		var banisterTopNormal = new PIXI.Sprite( app.loader.resources.banisterTopNormal.texture );
		var banisterBottom = new PIXI.Sprite( app.loader.resources.banisterBottom.texture );
		var banisterBottomNormal = new PIXI.Sprite( app.loader.resources.banisterBottomNormal.texture );
	
		var sizeCorrection = 0;
		if ( ( this.firstBanister ) || ( this.lastBanister ) ){

			sizeCorrection = banisterTop.height;

		}
		var banister = new PIXI.extras.TilingSprite( app.loader.resources.banister.texture, app.loader.resources.banister.texture.width, ( app.pixi.screen.height * sc ) - sizeCorrection );
		var banisterNormal = new PIXI.extras.TilingSprite( app.loader.resources.banisterNormal.texture, app.loader.resources.banister.texture.width, ( app.pixi.screen.height * sc ) - sizeCorrection );
		

		var container = new PIXI.Container();
		
		banister.position.y = sizeCorrection;
		
		container.addChild( banister );
		
		if( this.lastBanister ) container.addChild( banisterTop );
		
		if( this.firstBanister ){

			banister.position.y = 0;
			container.addChild( banisterBottom );
			banisterBottom.position.y =  ( app.pixi.screen.height * sc ) - banisterBottom.height;

		}

		

		var containerNormal = new PIXI.Container();

		containerNormal.addChild(banisterNormal);
		banisterNormal.position.y = sizeCorrection;

		if( this.lastBanister )  containerNormal.addChild( banisterTopNormal );
		
	

		if( this.firstBanister ){ 

			banisterNormal.position.y = 0;
			containerNormal.addChild( banisterBottomNormal );
			banisterBottomNormal.position.y =  ( app.pixi.screen.height * sc ) - banisterBottom.height;
		
		}


		if( this.firstBanister ) this.firstBanister = false;
		
		
		container.position.x =  containerNormal.position.x = ( app.pixi.screen.width * 1.12 ) - banister.width;
		
		return { banister : container, banisterNormal : containerNormal };

	};

	PlayScreen.prototype.getDestroyTile = function(  ){


		var destroy = new PIXI.Sprite( app.loader.resources[ "destoy" ].texture );
		var destroyNormal = new PIXI.Sprite( app.loader.resources[ "destroyNormal" ].texture );
		destroyNormal.position.y = destroy.position.y = 0;
		destroyNormal.position.x = destroy.position.x = 0;

		return { destroy : destroy, destroyNormal : destroyNormal };
	
	};

	PlayScreen.prototype.getDebri = function( tilePos ){

		var leafNum = 1;
		var debri = new PIXI.Sprite( app.loader.resources[ "debri" + leafNum ].texture );
		var debriNormal = new PIXI.Sprite( app.loader.resources[ "debriNormal" + leafNum ].texture );
		debriNormal.position.y = debri.position.y = this.getRandomArbitrary( 0, app.pixi.screen.height );
		debriNormal.position.x = debri.position.x = this.getRandomArbitrary( 0, app.pixi.screen.width );

		return { debri : debri, debriNormal : debriNormal };
	
	};
	
	PlayScreen.prototype.getCrack = function( tilePos ){

		var leafNum = this.getRandomArbitrary( 1, 3 );
		var crack = new PIXI.Sprite( app.loader.resources[ "crack" + leafNum ].texture );
		var crackN = new PIXI.Sprite( app.loader.resources[ "crackNormal" + leafNum ].texture );
		crackN.position.y = crack.position.y = this.getRandomArbitrary( 0, app.pixi.screen.height );
		crackN.position.x = crack.position.x = this.getRandomArbitrary( 0, app.pixi.screen.width );

		return { crack : crack, crackNormal : crackN };
	
	};
	
	PlayScreen.prototype.getLeaf = function( tilePos ){

		var leafNum = this.getRandomArbitrary( 1, 4 );
		var leaf = new PIXI.Sprite( app.loader.resources[ "leaf" + leafNum ].texture );
		var leafN = new PIXI.Sprite( app.loader.resources[ "leafNormal" + leafNum ].texture );
		leafN.position.y = leaf.position.y = this.getRandomArbitrary( 0, app.pixi.screen.height );
		leafN.position.x = leaf.position.x = this.getRandomArbitrary( 0, app.pixi.screen.width );


		return { leaf : leaf, leafNormal : leafN };

	};

	PlayScreen.prototype.getLightSprite = function( sprite, normalSprite ){

		var lightSprite = new PIXI.Sprite( sprite );
		lightSprite.normalTexture = normalSprite;
		lightSprite.pluginName = "lightSprite";
		return lightSprite;
	
	};

	PlayScreen.prototype.getDirectionalLight = function(){

		return new PIXI.lights.DirectionalLight({  

			color : 0xffdd66,
			brightness : 0.6,
			ambientColor : 0x222,
			ambientBrightness : 0.6,
			
			position: {
			
				x: 0,
				y: 0,
				z: this.lightHeight
			
			},

			target: {
				
				x: 0,
				y: 0,
				z: 0

			}
		});

	};
	
	PlayScreen.prototype.getPointLight = function(){

		return new PIXI.lights.PointLight({

			color : 0xffffff,
			brightness : 2,
			falloff : [ 0.3, 12, 100 ],
			position : {

				x : window.innerWidth,
				y : window.innerHeight,
				z : this.lightHeight
			
			}

		});

	};

	PlayScreen.prototype.getRandomArbitrary = function( min, max ) {

		return Math.floor( Math.random() * ( max - min ) + min ) ;
	
	};

	