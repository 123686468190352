/*///////////////////////////////////////////////////
                _                                
  ___ _ __   __| |  ___  ___ _ __ ___  ___ _ __  
 / _ \ '_ \ / _` | / __|/ __| '__/ _ \/ _ \ '_ \ 
|  __/ | | | (_| | \__ \ (__| | |  __/  __/ | | |
 \___|_| |_|\__,_| |___/\___|_|  \___|\___|_| |_|
                                                 
*////////////////////////////////////////////////////

EndScreen = function ( time ) {

	this.screen = app.pixi.screen;
	this.gameTime = 0;
	this.gameTimeFinal = app.gameTime;
	PIXI.Container.call( this );
	this.addBk();
	this.addSprites();

};

EndScreen.prototype = Object.create( PIXI.Container.prototype );

EndScreen.prototype.constructor = EndScreen;

	
	EndScreen.prototype.addBk = function() {

		let splshBkg = new PIXI.Sprite( this.getTexture( "endBk" ) );
		splshBkg.anchor.set( 0.5, 0.5 );
		splshBkg.position.set( this.screen.width/2 , this.screen.height/2 );

		let ratio = splshBkg.height / splshBkg.width;
		splshBkg.width = this.screen.width * 1.2;
		splshBkg.height = this.screen.width * ratio;
		
		if(splshBkg.height<this.screen.height ){

			splshBkg.height = this.screen.height * 1.2;
			splshBkg.width = splshBkg.height/ratio;
		}

		splshBkg.posX = splshBkg.position.x;
 		splshBkg.posY = splshBkg.position.y;
		this.addChild( splshBkg );

		gyro.frequency = 10;
		gyro.startTracking( function( o ) {

			TweenMax.to( splshBkg.position, 2, { x : splshBkg.posX +  (20 * o.x) / 4  } );
			TweenMax.to( splshBkg.position, 2, { y : splshBkg.posY  + (10 * o.y) / 4 } );
		
		}.bind( this ) );

	
	};

	EndScreen.prototype.addSprites = function( item, percent ){

		///////////////////////////////////////////////////
   		//TT
   		///////////////////////////////////////////////////
		
		this.uiTitle = new PIXI.Sprite( this.getTexture( "uiTT" ) );
		this.uiTitle.anchor.set( 0.5, 0.5 );
		
		this.percentScale( this.uiTitle, .93 ); //SCALE DOWN TO MATCH 1080*1920
		
		this.uiTitle.position.set( app.pixi.screen.width / 2 , ( app.pixi.screen.height - this.uiTitle.height / 2 ) - 15 );
		this.addChild( this.uiTitle );
		this.uiTitle.interactive = true;
		this.uiTitle.on( 'pointerdown', function(){

			app.dispatcher.dispatch("INSTRUCTIONS")
	
		}.bind(this ) );


		//	var creditStyle = new PIXI.TextStyle({
		//			fontFamily : localisation.fontApp,
		//			fontSize : localisation.preloadScreen.credits.fontsize * app.WIDTH_RATIO,
		//			fill: ['#a48f65'], // gradient
		//			wordWrap: true,
		//			wordWrapWidth: app.pixi.screen.width,
		//			align:"center"
		//		});
//
		//this.preloadCreditText = new PIXI.Text( localisation.preloadScreen.credits.copy, creditStyle );
		//
		//this.preloadCreditText.anchor.set(1,1);
//
		//this.preloadCreditText.position.set( app.pixi.screen.width - 20* app.WIDTH_RATIO, app.pixi.screen.height -20* app.WIDTH_RATIO );
		//this.addChild( this.preloadCreditText );

		/////////////////////////////////////////////////
		// UI
		////////////////////////////////////////////////
		
		this.uiContainer = new PIXI.Container();
		
		//BACKGROUND
		this.boxHeight = 944
		if(localisation.endScreen.tickets.visible) this.boxHeight = 1044;
		var uiBKwidth = app.pixi.screen.width * ( 702 / 1080 )
		var uiBKheight = uiBKwidth * this.boxHeight / 702; //app.pixi.screen.height * ( 944 / 1920 )
		this.uiBk = this.getRoundSquare(0xb6a07a,0xff000,0,uiBKwidth, uiBKheight,20 );
		this.uiContainer.addChild( this.uiBk );

		////////////////////////////////////////////////////////
		//TITLE
		///////////////////////////////////////////////////////
		this.addTitle();
	
		this.addTime();
		
		this.addButtons();

		
		var uiBKpos  = app.pixi.screen.height - this.uiTitle.height / 2;
		this.uiContainer.position.set( app.pixi.screen.width / 2, uiBKpos / 2.2 );




		this.addChild( this.uiContainer );

	};
	
	EndScreen.prototype.addTitle = function( ){

		title = new MultiStyleText(localisation.endScreen.title.copy,
		{
			"default": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.endScreen.title.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#a48f65",
				align: "center",
				padding : 10
			},
			"red": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.endScreen.title.fontSize * app.WIDTH_RATIO+ "px",
				fill: "#ff0000",
				align:"center",
				padding : 10
			}
			
		});

		title.anchor.set(0.5,0.5)
		this.uiContainer.addChild( title ); 
		
		var percentPosT	=  ( 125 / this.boxHeight ) * this.uiContainer.height;
		
		title.position.set( 0, - this.uiContainer.height / 2  +  percentPosT );

		this.uiContainer.addChild( title );

	};

	EndScreen.prototype.addTime = function(){

		this.uiTime = new PIXI.Sprite( this.getTexture( "timeBox" ) );
		this.uiTime.anchor.set( 0.5, 0.5 );

		this.percentScale( this.uiTime, .60 );  //SCALE DOWN TO MATCH 1080*1920

		var percentPosTime	=  ( 294 / this.boxHeight ) * this.uiContainer.height;
		this.uiTime.position.set( 0 , - this.uiContainer.height/2  +  percentPosTime );
		this.uiContainer.addChild( this.uiTime );
		
		var style = new PIXI.TextStyle({
   		
   				fontFamily : 'Roboto',
   				fontSize : 40,
   				fill: ['#b6a07a'], // gradient
   				wordWrap: true,
   				wordWrapWidth: 450,
   				textAlign:"center"
		
		});

		this.time = new PIXI.Text( "00:00:00",  style );

		this.time.anchor.set( 0.5, 0.5 );
		this.uiTime.addChild( this.time );

	};

	EndScreen.prototype.addButtons = function(){

		this.shareBtn = this.getButton(localisation.endScreen.share,'#030304',false,'share');
		this.playBtn = this.getButton(localisation.endScreen.restart,'#030304',false,'play');
		this.trailerBtn = this.getButton(localisation.endScreen.trailer,'#030304',false,'trailer');
		this.ticketsBtn = this.getButton(localisation.endScreen.tickets,'#030304',true,'tickets');
		
		this.uiContainer.addChild( this.shareBtn );
		this.uiContainer.addChild( this.playBtn );

		
		
		this.uiContainer.addChild( this.trailerBtn );

		if(!localisation.endScreen.tickets.visible){

			this.playBtn.position.set(0, this.shareBtn.height*1.2)
			this.trailerBtn.position.set(0, this.shareBtn.height*2.4)
		
		}else{
			this.shareBtn.position.y = -this.shareBtn.height/2
			this.uiContainer.addChild( this.ticketsBtn );
			this.playBtn.position.set(0, (this.shareBtn.position.y)+this.shareBtn.height*1.2)
			this.trailerBtn.position.set(0, (this.shareBtn.position.y)+this.shareBtn.height*2.4)
			this.ticketsBtn.position.set(0,(this.shareBtn.position.y)+ this.shareBtn.height*3.6)
		}
		
		if( Detectizr.device.type === "tablet" ){

			this.uiContainer.scale.set(0.9,0.9)
		
		}
		this.uiContainer.height = Math.floor( this.uiContainer.height)
		
	}
	
	EndScreen.prototype.addSocial = function( e ){

		this.socialIcons = new PIXI.Container();
		if( localisation.social.fb.active ){
	
			this.fb = new PIXI.Sprite( this.getTexture( "fb" ) );
			this.socialIcons.addChild( this.fb );
			this.fb.interactive = true;
			this.fb.on( 'pointerup', function(){
				gtag('event', 'facebook_click', {'event_label': 'clicks facebook share','event_category': 'shares to facebook'});
				var link = "https://www.facebook.com/sharer/sharer.php?u="+encodeURI(localisation.social.fb.link);
				window.open( link, '_BLANK');
			
			})
			
	
		}
		if( localisation.social.tw.active ){
	
			this.tw = new PIXI.Sprite( this.getTexture( "tw" ) );
			this.socialIcons.addChild( this.tw );
			this.tw.interactive = true;
			this.tw.on( 'pointerup', function(){
				gtag('event', 'twitter_click', {'event_label': 'clicks twitter share','event_category': 'shares to twitter'});
				window.open("https://twitter.com/intent/tweet?text="+encodeURI(localisation.social.tw.line1+this.time.text+" "+localisation.social.tw.line2 )+"&url="+encodeURI(localisation.social.tw.link)+"&hashtags="+encodeURI(localisation.social.tw.hashtag),'_blank');
			
			}.bind( this ) );

		}

	


		if( localisation.social.whats.active ){

			this.ins = new PIXI.Sprite( this.getTexture( "whats" ) );
			this.socialIcons.addChild( this.ins );
			this.ins.interactive = true;

			this.ins.on( 'pointerup', function(){
			
				window.open("whatsapp://send?text="+encodeURI(localisation.social.whats.line1+this.time.text+" "+localisation.social.whats.line2 +" ")+encodeURI(localisation.social.whats.link),'_blank');
			
			}.bind( this ))
	
		}
		for( var i = 0; i < this.socialIcons.children.length; i++ ){
		
			if( i > 0 ){
		
				this.socialIcons.children[ i ].position.x = this.socialIcons.children[ i-1 ].position.x +  this.socialIcons.children[ i-1 ].width*1.5
		
			}
		
		}
		//this.uiContainer.position.y -=  this.socialIcons.height * 0.8;


		this.socialIcons.position.x = (app.pixi.screen.width/2) - this.socialIcons.width/2;
		this.socialIcons.position.y = this.boxShare.position.y + this.boxShare.height/1.9;
		this.shareContainer.addChild( this.socialIcons );

	};

	
	EndScreen.prototype.onBtnTouch = function( e ){
		
		var container = e.target;

		//if( container.isReverse ){
//
		//	container.bk.visible = true;
		//	container.bkOn.visible = false;
//
		//	container.copy.style.fill = 0xb6a07a;
		//	
		//}else{
//
		//	container.bk.visible = false;
		//	container.bkOn.visible = true;
		//	container.copy.style.fill = 0x050506;
//
		//
		//}
		
		if(container.type == "share"){

			gtag('event', 'share_click', {'event_label': 'clicks share ','event_category': 'user clicks on share'});
	
			this.loadShare();
			return
		}
		if(container.type == "tickets"){

			gtag('event', 'tickets_click', {'event_label': 'clicks tickets ','event_category': 'visits get tickets link'});

			window.open(localisation.endScreen.tickets.link,"_blank");
			return
		}
	
		if(container.type =="trailer"){
			gtag('event', 'trailer_click', {'event_label': 'clicks trailer ','event_category': 'user clicks on trailer'});
	
			Howler.mute(true)
			eventFire( document.getElementById(  'js-modal-video' ), 'click' );

			document.getElementsByClassName( 'modal-video-close-btn')[ 0 ].addEventListener( 'click', app.onHideTrailer );
			document.getElementsByClassName( 'modal-video')[ 0 ].addEventListener( 'click', app.onHideTrailer );
			return;
		}
		if(container.type == "play"){
			gtag('event', 'replay_click', {'event_label': 'clicks replay ','event_category': 'user replays game'});
			app.step1.stop();
			app.step2.stop();
			app.step3.stop();
			app.levelUpSound.stop();
			app.danger3.stop();
			app.danger2.stop();
			app.danger1.stop();

		} app.dispatcher.dispatch( "RESTART" );
		container.copy.dirty = true;

	};

	EndScreen.prototype.onBtnTouchUp = function( e ){

		var container = e.target;

	//if( container.isReverse ){

	//	container.bk.visible = false;
	//	container.bkOn.visible = true;
	//	container.copy.style.fill = 0x050506;

	//
	//}else{
	//
	//	container.bk.visible = true;
	//	container.bkOn.visible = false;
	//	container.copy.style.fill = 0xb6a07a;
	//	}
	//	container.copy.dirty = true;

	};

	/*///////////////////////////////////////
	 _          _                     
	| |__   ___| |_ __   ___ _ __ ___ 
	| '_ \ / _ \ | '_ \ / _ \ '__/ __|
	| | | |  __/ | |_) |  __/ |  \__ \
	|_| |_|\___|_| .__/ \___|_|  |___/
	             |_|                  

	*///////////////////////////////////////
	
	EndScreen.prototype.getButton = function( copy, copyColor,active,type){

		var container = new PIXI.Container();
		container.interactive = true;
		//container.on( 'pointerdown', this.onBtnTouch.bind(this) );
		container.on( 'pointerup', this.onBtnTouch.bind(this) );

		var bk = this.getRoundSquare( 0xa48f65, 0xa48f65, 1, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		var bkOn = this.getRoundSquare( 0xc82323, 0xc82323, 1, 562 * app.WIDTH_RATIO , 128 * app.WIDTH_RATIO ,8 );
		
		container.addChild( bk );
		container.addChild( bkOn );
		container.bk = bk
		container.bkOn = bkOn;
		container.type = type;
		
		container.isReverse = active;

		bkOn.visible = false;

		if( active ){
		
			bk.visible = false;
			bkOn.visible = true;
		
		}
		
		var text = new PIXI.Text( copy.copy , new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : copy.fontSize * app.WIDTH_RATIO  ,
   				fill : [ copyColor ], // gradient
   				wordWrap : true,
   				wordWrapWidth : 450,
   				textAlign : "center",
				padding : 10

			})
		); 
		
		text.anchor.set(0.5,0.5);
		container.copy = text;
		container.addChild( text );
		return container;
	}

	EndScreen.prototype.getRoundSquare = function( border,fill,fillAlpha, w, h,r ){

		var sq = new PIXI.Graphics();
		sq.lineStyle( 2, border, 1 );
		sq.beginFill( fill, fillAlpha );
		sq.drawRoundedRect( -w / 2, -h / 2, w, h, r );
		return sq;
	
	};

	EndScreen.prototype.percentScale = function( item, percent ){

		if( item.width > app.pixi.screen.width * percent ){

			var ratio  = item.height / item.width;
			item.width  = Math.floor(app.pixi.screen.width * percent);
			item.height = Math.floor(item.width * ratio);

		
		}else{

		}

	};
	
	EndScreen.prototype.onTick = function(){
		
		if( this.gameTime <= this.gameTimeFinal ){
		
			this.onTimeTick();
		
		}

	}

	EndScreen.prototype.onTimeTick = function(){

	

		var mins = Math.floor( this.gameTime / 60 );
		var secs =  this.gameTime - ( mins* 60 ) ;
		if( mins < 10 ) mins = "0" + mins;
		if( secs < 10 ) secs = "0" + secs;

		this.time.text =  "00:" +mins + ":" + secs  ;

		this.gameTime += 1;
	};

	EndScreen.prototype.getTexture = function( name ){

		return  app.loader.resources[ "" + name ].texture;
	
	};
	EndScreen.prototype.getTexture2 = function( name ){

		return  app.loader2.resources[ "" + name ].texture;
	
	};

	EndScreen.prototype.loadShare = function( ){

		
			if(this.shareContainer){

				this.addChild(this.shareContainer);
				//this.addChild( this.tt2 );
				TweenMax.delayedCall( 1, function(){

				this.shareContainer.interactive = true;

			}.bind( this ));

			}else{

				this.createShare();

			}
		
	

	};

	EndScreen.prototype.onCompleteLoad = function( ){

		app.shareLoaded= true;
		this.createShare();

	};

	EndScreen.prototype.createShare = function( ){

		this.gameTime = this.gameTimeFinal;
		this.onTick();

		this.shareContainer = new PIXI.Container();

		/////////////////////////////////////////////
		//BACKGROUND
		////////////////////////////////////////////

		let bg = new PIXI.Sprite(app.loader.resources.bk_share.textures[ "share_bg.jpg" ] );
		bg.anchor.set( 0.5, 0.5 );
		let ratio = bg.height / bg.width;
		bg.width = this.screen.width;
		bg.height = this.screen.width * ratio;
		
		if( bg.height < this.screen.height ){

			bg.height = this.screen.height;
			bg.width = bg.height / ratio;
		
		}


		
		bg.position.set( this.screen.width / 2 , this.screen.height / 2 );

		this.shareContainer.addChild( bg );
		
		//subtitle
		var style = new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : localisation.endScreen.shareTitle0.fontSize*app.WIDTH_RATIO +  "px",
   				fill: ['#b8b9bb'], // gradient
   				wordWrap: true,
   				wordWrapWidth: 400 ,
   				align:"center"
		
		});

		//BOX
		var box = new PIXI.Sprite(app.loader.resources.bk_share.textures[ "share_box.png" ] );
		box.anchor.set( 0.5 );
		
		this.percentScale( box, 873 / 1080 ); //873 IS THE WIDTH OF THE BOX DIVIDED BY 1080 PSD DESIGN SCREEN WIDTH
		box.position.set(  this.screen.width/2 , this.screen.height/2.2 );
		this.shareContainer.addChild( box );
		
		var title = new MultiStyleText(localisation.endScreen.shareTitle.copy,
		{
			"default": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.endScreen.shareTitle.fontSize* 0.4 + "px",
				fill: "#b8b9bb",
				align: "center",
				padding : 10
			},
			"red": {
				fontFamily: localisation.fontApp,
				fontSize: localisation.endScreen.shareTitle.fontSize *0.4+ "px",
				fill: "#ff0000",
				align:"center",
				padding : 10
			}
			
		});

		var percentPosT	=  ( 80 / 516 ) * box.height;
		title.anchor.set(0.5,0)
		//title.position.set( this.screen.width/2, box.position.y - box.height / 2 + title.height  + box.height*0.07);

		

		//TIME
		var uiTime = new PIXI.Sprite( app.loader.resources.bk_share.textures[ "share_timerbox.png" ] );
		 uiTime.anchor.set( 0.5, 0.5 );

		this.percentScale( uiTime, .90 );  //SCALE DOWN TO MATCH 1080*1920
		uiTime.height = Math.floor( uiTime.height)
		var percentPosTime	=  ( 240 / 516 ) * box.height;
		uiTime.position.set( 0 , - box.height/2  +  percentPosTime );
		box.addChild( uiTime );
		
		var style = new PIXI.TextStyle({
   		
   				fontFamily : 'Roboto',
   				fontSize : 40,
   				fill: ['#b8b9bb'], // gradient
   				wordWrap: true,
   				wordWrapWidth: 450*app.WIDTH_RATIO ,
   				textAlign:"center"
		
		});

		sharetime = new PIXI.Text( this.time.text,  style );
		sharetime.anchor.set( 0.5, 0.5 );
		uiTime.addChild( sharetime );
		box.addChild( uiTime );

		box.height = Math.floor( box.height)

		title.anchor.set(0.5,1)
		var gap = ( (box.height/2) - (uiTime.height/2) - title.height)/2;

		title.position.x = 0;
		title.position.y = (-  uiTime.height/2 ) - gap;

		box.addChild( title );

		this.boxShare = box;
		//subtitle
		var style = new PIXI.TextStyle({
   		
   				fontFamily : localisation.fontApp,
   				fontSize : localisation.endScreen.shareTitle2.fontSize * 0.4 +  "px",
   				fill: ['#b8b9bb'], // gradient
   				wordWrap: true,
   				wordWrapWidth: box.width*0.8 ,
   				align:"center"
		
		});

		title2 = new PIXI.Text( localisation.endScreen.shareTitle2.copy,  style );
		title2.anchor.set( 0.5,0);

		var gap = ( (box.height/2) - (uiTime.height/2) - title2.height)/2
		title2.position.x = 0;
		title2.position.y = (  uiTime.height/2 )+ gap;
		title2.style.padding = 10
		//title2.position.set( this.screen.width/2, box.position.y + box.height / 2  - title.height - box.height*0.07);
		//if(Detectizr.device.type == "tablet") title2.position.set( this.screen.width/2, box.position.y + box.height / 2  - title.height);


		box.addChild( title2 );



		////////////////////////////////////////////
		//TT
		///////////////////////////////////////////
		
		this.tt2 = new PIXI.Sprite( this.getTexture( "uiTT" ) );
		this.tt2.anchor.set( 0.5, 0.5 );
		this.percentScale( this.tt2, .93 ); //SCALE DOWN TO MATCH 1080*1920
		this.tt2.position.set( app.pixi.screen.width / 2 , ( app.pixi.screen.height - this.tt2.height / 2 ) - 15 );
		this.shareContainer.addChild( this.tt2 );

		//	var creditStyle = new PIXI.TextStyle({
		//			fontFamily : localisation.fontApp,
		//			fontSize : localisation.preloadScreen.credits.fontsize * app.WIDTH_RATIO,
		//			fill: ['#a48f65'], // gradient
		//			wordWrap: true,
		//			wordWrapWidth: app.pixi.screen.width,
		//			align:"center"
		//		});
//
		//this.preloadCreditText = new PIXI.Text( localisation.preloadScreen.credits.copy, creditStyle );
		
		//this.preloadCreditText.anchor.set(1,1);

		//this.preloadCreditText.position.set( app.pixi.screen.width - 20* app.WIDTH_RATIO, app.pixi.screen.height -20* app.WIDTH_RATIO );
		//this.shareContainer.addChild( this.preloadCreditText );
		////////////////////////////////////////////
		//BACK
		///////////////////////////////////////////
		this.back = new PIXI.Sprite( app.loader.resources.bk_share.textures[ "shareimage_backbtn.png"] );
	//	this.back.anchor.set( 0.5, 0.5 );
		this.percentScale(this.back, .1 ); //SCALE DOWN TO MATCH 1080*1920
		this.back.interactive = true;
		this.shareContainer.addChild( this.back );	
		this.back.position.set(40*app.WIDTH_RATIO,40*app.WIDTH_RATIO)
		this.back.on( 'pointerdown', function(){
			this.shareBtn.bkOn.visible = false;
			this.shareBtn.bk.visible = true;
			this.removeChild( this.shareContainer );
	
		}.bind(this ) );
		this.shareContainer.interactive = true;

		if(!localisation.snapchat ) this.addSocial();
		this.addChild( this.shareContainer );



	}




